@use "../../global" as g;
// ----------------------------------------------------
//  下層ページのヘッダー
// ----------------------------------------------------
.pageHeader {
  $this: &;
  margin-bottom: 55px;
  @include g.media(sp) {
    margin-bottom: 25px;
  }
  &__title {
    font-size: 4.2rem;
    text-align: center;
    font-weight: bold;
    line-height: 1.4;
    @include g.media(sp) {
      font-size: 2.8rem;
    }
  }
  &__shoulder {
    display: block;
    font-size: 2.8rem;
    font-weight: normal;
  }
  &.-productsDetail {
    #{$this} {
      &__title {
        font-size: 3.2rem;
        text-align: left;
      }
    }
  }
}
