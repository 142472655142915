@use "../../global" as g;
// -----------------------------------------
// 画像＋テキストの一覧
// -----------------------------------------
.imageList {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  @include g.media(sp) {
    margin-bottom: 15px;
  }
  a {
    color: g.$color-font;
  }
  &__item {
    margin-bottom: 30px;
  }
  &__link {
    position: relative;
    display: block;
    &:hover {
      color: g.$color-main;
      text-decoration: none;
    }
  }
  &__image {
    display: block;
    background: white;
    margin-bottom: 14px;
    text-align: center;
    @include g.media(sp) {
      margin-bottom: 10px
    }
  }
  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 2.0rem;
    line-height: 1.5;
    @include g.media(sp) {
      font-size: 1.5rem;
    }
    .blankIcon {
      vertical-align: baseline;
      transform: translateY(3px);
      @include g.media(sp) {
        width: 15px;
      }
    }
  }
  &__text {
    display: block;
    line-height: 1.6;
    font-size: 1.8rem;
    @include g.media(tablet) {
      font-size: 1.6rem;
    }
    @include g.media(sp) {
      font-size: 1.4rem;
    }
    &.-fz20 {
      font-size: 2rem;
      @include g.media(tablet) {
        font-size: 1.8rem;
      }
      @include g.media(sp) {
        font-size: 1.5rem;
      }
    }
    &.-bold {
      font-weight: bold;
    }
  }
  &__shoulder {
    display: block;
    line-height: 1.6;
    font-size: 1.4rem;
  }
  &__subText {
    display: block;
    line-height: 1.6;
    font-size: 1.4rem;
  }
  // NEWアイコン
  &__new {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: -13px;
    left: -13px;
    @include g.z-index(newIcon);
    width: 40px;
    height: 40px;
    margin: 0;
    background: white;
    color: g.$color-main;
    font-size: 1.1rem;
    font-weight: bold;
    border: 1px solid g.$color-main;
    border-radius: 50%;
    text-transform: uppercase;
    @include g.media(sp) {
      width: 60px;
      height: 60px;
      font-size: 1.8rem;
      top: -20px;
      left: -10px;
    }
  }
  // スライダー用
  &.-slide {
    flex-wrap: nowrap;
  }
  // 画像ボーダーで囲う
  &.-border {
    #{$this} {
      &__image {
        border: 1px solid g.$color-gray;
        transition: all .8s;
      }
      &__link {
        &:hover {
          .imageList__image {
            border-color: red;
          }
        }
      }
    }
  }
  // 商品情報の新商品
  &.-newProducts {
    #{$this} {
      &__item {
        padding: 13px 15px 0;
        @include g.media(sp) {
          padding-top: 20px;
        }
      }
    }
  }
  // CMの新着
  &.-newCM {
    #{$this} {
      &__item {
        padding: 13px 13px 0;
        @include g.media(sp) {
          padding: 20px 15px 0;
        }
      }
    }
  }
  // 3カラム
  &.-col3 {
    margin-left: -23px;
    margin-right: -23px;
    @include g.media(tablet) {
      margin-left: -15px;
      margin-right: -15px;
    }
    @include g.media(sp) {
      margin-left: -7px;
      margin-right: -7px;
    }
    #{$this} {
      &__item {
        box-sizing: border-box;
        width: calc(33.333%);
        padding: 0 23px;
        @include g.media(tablet) {
          padding: 0 15px;
        }
        @include g.media(sp) {
          width: calc(50%);
          padding: 0 7px;
        }
      }
    }
  }
  // 4カラム
  &.-col4 {
    margin-left: -14px;
    margin-right: -14px;
    @include g.media(tablet) {
      margin-left: -7px;
      margin-right: -7px;
    }
    #{$this} {
      &__item {
        box-sizing: border-box;
        width: calc(25%);
        padding: 0 13.5px;
        @include g.media(tablet) {
          padding: 0 10px;
        }
        @include g.media(sp) {
          width: calc(50%);
          padding: 0 7px;
        }
      }
    }
  }
  // 5カラム
  &.-col5 {
    margin-left: -12px;
    margin-right: -12px;
    @include g.media(tablet) {
      margin-left: -6px;
      margin-right: -6px;
    }
    @include g.media(sp) {
      margin-left: -10px;
      margin-right: -10px;
    }
    @include g.media(sp-m) {
      margin-left: -7px;
      margin-right: -7px;
    }
    #{$this} {
      &__item {
        box-sizing: border-box;
        width: calc(100% / 5);
        padding: 0 12px;
        @include g.media(tablet) {
          padding: 0 6px;
        }
        @include g.media(sp) {
          width: calc(100% / 3);
          padding: 0 10px;
          font-size: 1.4rem;
        }
        @include g.media(sp-m) {
          width: calc(100% / 2);
          padding: 0 7px;
        }
      }
    }
  }
  // 6カラム
  &.-col6 {
    margin-left: -12px;
    margin-right: -12px;
    @include g.media(tablet) {
      margin-left: -6px;
      margin-right: -6px;
    }
    @include g.media(sp) {
      margin-left: -14px;
      margin-right: -14px;
    }
    @include g.media(sp-m) {
      margin-left: -7px;
      margin-right: -7px;
    }
    #{$this} {
      &__item {
        box-sizing: border-box;
        width: calc(100% / 6);
        padding: 0 12px;
        @include g.media(tablet) {
          padding: 0 6px;
        }
        @include g.media(sp) {
          padding: 0 14px;
          width: calc(100% / 3);
          font-size: 1.4rem;
        }
        @include g.media(sp-m) {
          padding: 0 7px;
        }
      }
    }
  }
  // 横に並ぶ
  &.-row {
    @include g.media(tablet-s) {
      margin-left: -15px;
      margin-right: -15px;
    }
    @include g.media(sp) {
      margin-left: -7px;
      margin-right: -7px;
    }
    #{$this} {
      &__item {
        margin-right: 22px;
        &:last-child {
          margin-right: 0;
        }
        @include g.media(tablet-s) {
          box-sizing: border-box;
          width: calc(50%);
          margin: 0 auto;
          padding: 0 15px;
        }
        @include g.media(sp) {
          padding: 0 7px;
        }
      }
      &__image {
        @include g.media(tablet-s) {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  // SPは1カラム
  &.-sp-col1 {
    #{$this} {
      &__item {
        @include g.media(sp) {
          width: auto;
        }
      }
    }
  }
  // SPは1カラムでテキスト横
  &.-sp-col1_textSide {
    #{$this} {
      @include g.media(sp) {
        &__item {
          width: auto;
          margin-bottom: 25px;
        }
        &__link {
          display: flex;
        }
        &__image {
          margin-right: auto;
          margin-bottom: 0;
          width: calc(50% - 10px);
        }
        &__textWrap {
          width: 50%;
        }
      }
    }
  }
}

