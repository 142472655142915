@use "../../global" as g;
// -----------------------------------------
// トップページの企業情報
// -----------------------------------------
.top-pickupList {
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
  @include g.media(tablet-s) {
  }
  @include g.media(sp) {
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
  }
  &__item {
    width: 184px;
    margin: 0 20px;
    @include g.media(tablet) {
      margin: 0 15px;
    }
    @include g.media(tablet-s) {
      margin: 0 10px;
    }
    @include g.media(sp) {
      box-sizing: border-box;
      width: calc(100% / 3);
      margin: 0 0 20px;
      padding: 0 7px;
    }
    @include g.media(sp-m) {
    }
  }
  &__link {
    display: block;
    color: g.$color-font;
    text-align: center;
    &:hover {
      text-decoration: none;
      .top-pickupList__image {
        border-color: g.$color-main;
      }
    }
  }
  &__text {
    position: relative;
    display: block;
    margin-top: 15px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    @include g.media(tablet-l) {
      font-size: 1.8rem;
    }
    @include g.media(tablet) {
      font-size: 1.6rem;
    }
    @include g.media(tablet-s) {
      font-size: 1.4rem;
    }
    @include g.media(sp) {
      margin-top: 10px;
      padding-right: 10px;
      letter-spacing: -1px;
    }
  }
  &__image {
    box-sizing: border-box;
    height: auto;
    border: 2px solid g.$color-gray;
    transition: border-color .8s;
    @include g.media(sp) {
      width: 100%;
    }
  }
  &__blank {
    position: absolute;
    top: calc(50% - 12px);
    right: 1px;
    transform: translateY(0);
    @include g.media(sp) {
      top: calc(50% - 8px);
    }
  }
}

