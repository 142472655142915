@use "../../global" as g;
// ----------------------------------------------------
//  お問い合わせ先
// ----------------------------------------------------
.contactBox {
  padding: 45px 45px 25px;
  background: g.$color-light;
  @include g.media(sp) {
    padding: 30px 10px 10px;
  }
  &__title {
    margin-bottom: 5px;
    line-height: 1.5;
    font-size: 3.2rem;
    text-align: center;
    @include g.media(sp) {
      font-size: 2rem;
    }
    @include g.media(w320) {
      font-size: 1.7rem;
    }
  }
  &__body {
    text-align: center;
    font-size: 1.8rem;
    @include g.media(sp) {
      font-size: 1.4rem;
    }
    @include g.media(w320) {
      font-size: 1.2rem;
    }
    p {
      margin-bottom: 15px;
    }
  }
}
