@use "../../global" as g;
// -----------------------------------------
// リスト
// -----------------------------------------
.list {
  // ・のリスト
  &.-dot {
    li {
      list-style-type: disc;
      margin-bottom: 7px;
    }
  }
  &.-rss {
    li {
      margin-bottom: 10px;
      a {
        color: g.$color-font;
      }
    }
  }
}

