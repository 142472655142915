@use "../../global" as g;
// ----------------------------------------------------
//  アレルギー情報 ご利用上の注意
// ----------------------------------------------------
.allergy-notes {
  &__body {
    ol {
      margin: 0;
      padding: 0;
      counter-reset: num;
      font-size: 1.8rem;
      @include g.media(sp) {
        font-size: 1.4rem;
      }
      > li {
        position: relative;
        list-style: none;
        margin-bottom: 45px;
        margin-left: 0;
        padding-left: 40px;
        @include g.media(sp) {
          padding-left: 15px;
        }
        &::before {
          counter-increment: num;
          content: counter(num);
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          background: g.$color-main;
          color: white;
          text-align: center;
          line-height: 40px;
          font-size: 2rem;
          @include g.media(sp) {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 1.4rem;
          }
        }
        ul {
          margin-left: 50px;
        }
      }
    }
    p {
      font-size: 1.8rem;
      @include g.media(sp) {
        font-size: 1.4rem;
      }
    }
  }
}
