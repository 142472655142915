@use "../global" as g;
// ----------------------------------------------------
//  グローバルナビゲーション
// ----------------------------------------------------
.navBody {
  display: flex;
  flex-direction: column-reverse;
  line-height: 1.2;
  @include g.media(tablet) {
    margin-left: auto;
    padding-top: 49px;
    // width: 100%;
  }
  @include g.media(sp) {
    display: none;
    position: absolute;
    top: 49px;
    left: 0;
    @include g.z-index(nav);
    padding: 0;
    width: 100%;
    background: white;
    border-top: 2px solid #D8D5D5;
  }
}

// グロナビ
.nav {
  max-width: g.$width-base;
  margin: auto;
  @include g.media(tablet-s) {
    width: 100%;
  }
  &__link {
    box-sizing: border-box;
    display: block;
    padding: 1px 10px;
    color: g.$color-font;
    font-weight: 700;
    @include g.media(tablet-l) {
      padding: 1px 0 1px 5px;
    }
    @include g.media(tablet) {
      padding: 1px 0;
    }
    @include g.media(sp) {
      position: relative;
      width: 100%;
      padding: 13px 25px 13px 15px;
      text-align: left;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 17px;
        width: 8px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_right.svg) no-repeat center;
        background-size: 100% auto;
      }
      &[aria-expanded] {
        &::after {
          right: 15px;
          width: 16px;
          background-image: url(../img/share/sp-arrow_menu_bottom.svg);
          transition: all .5s;
        }
        &.-open {
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    @include g.media(tablet-s) {
      justify-content: center;
    }
    @include g.media(sp) {
      display: block;
      text-align: left;
      font-size: 1.8rem;
    }
  }
  &__item {
    margin-left: 50px;
    &:first-child {
      margin-left: 0;
    }
    @include g.media(tablet-l) {
      margin-left: 28px;
    }
    @include g.media(tablet) {
      margin: 0 2vw;
    }
    @include g.media(sp) {
      display: block;
      margin: 0;
      border-bottom: 2px solid #D8D5D5;
    }
  }
}

// ドロップダウンメニュー
.nav__sub-nav {
  @include g.media(pc) {
    box-sizing: border-box;
    position: absolute;
    display: block;
    visibility: hidden;
    overflow: hidden;
    top: 98px;
    left: 0;
    z-index: -1;
    width: 100%;
    max-height: calc(100vh - 103px);
    overflow: auto;
    padding: 28px 30px 1px;
    background-color: #fff;
    border-top: 1px solid #D6CAC2;
    border-bottom: 1px solid #D6CAC2;
    transition: all .7s;
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
    &.-open {
      visibility: visible;
      z-index: 99;
      opacity: 1;
      height: auto;
      transform: translateY(0px);
    }
  }
  @include g.media(tablet) {
    top: 108px;
  }
  @include g.media(sp) {
    padding: 0;
    display: none;
    &.-open {
      display: block;
    }
  }
  @media print {
    display: none;
  }
}
.nav__sub-nav-inner {
  max-width: g.$width-base;
  margin: auto;
  @include g.media(sp) {
    background: #FAFAFA;
  }
}



// ドロップダウンメニュー内
// 商品情報、CM・キャンペーン、知る・楽しむ
.nav-subNav {
  text-align: left;
  a {
    display: inline-block;
    color: g.$color-font;
    @include g.media(sp) {
      position: relative;
      display: block;
      padding: 12px 15px 12px 30px;
      font-weight: normal;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 17px;
        width: 8px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_right.svg) no-repeat center;
        background-size: 100% auto;
      }
    }
    .blankIcon {
      transform: translateY(0px);
    }
  }
  &__child {
    margin-top: 10px;
    @include g.media(sp) {
      margin-top: 0;
    }
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 13px;
      &::before {
        content: "";
        position: absolute;
        top: .5em;
        left: 5px;
        width: 3px;
        height: 3px;
        background: g.$color-font;
        border-radius: 50%;
      }
      @include g.media(sp) {
        margin-bottom: 0;
        padding-left: 1em;
        &::before {
          top: 1.25em;
          left: 52px;
        }
      }
    }
  }
  &__title {
    @include g.media(sp) {
      border-top: 2px solid #D8D5D5;
      font-size: 1.6rem;
    }
    > a {
      margin-bottom: 25px;
      @include g.media(sp) {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include g.media(sp) {
      display: block;
    }
    li {
      // width: calc(100% / 7);
      margin-right: 80px;
      margin-bottom: 25px;
      &:last-child {
        margin-right: 0;
      }
      @include g.media(tablet) {
        width: auto;
        margin-right: 25px;
      }
      @include g.media(sp) {
        width: auto;
        margin: 0;
        border-top: 1px solid #D8D5D5;
        a {
          font-size: 1.6rem;
        }
      }
    }
  }
}


// サステナビリティ
.nav-sustainability {
  text-align: left;
  a {
    display: inline-block;
    color: g.$color-font;
    @include g.media(sp) {
      position: relative;
      display: block;
      padding: 12px 25px 12px 30px;
      font-weight: normal;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 17px;
        width: 8px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_right.svg) no-repeat center;
        background-size: 100% auto;
      }
    }
    .blankIcon {
      transform: translateY(0px);
    }
  }
  &__title {
    @include g.media(sp) {
      border-top: 2px solid #D8D5D5;
      font-size: 1.6rem;
    }
    > a {
      margin-bottom: 25px;
      @include g.media(sp) {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }
  &__list {
    column-count: 3;
    padding-bottom: 25px;
    @include g.media(sp) {
      display: block;
      column-count: auto;
      padding-bottom: 0;
    }
  }
  &__item {
    margin-bottom: 25px;
    // ステークホルダーエンゲージメントの下に2つ分の余白を設けて、次のリストを別カラムに移動
    &:nth-child(3) {
      &::after {
        content: "";
        display: block;
        height: 5.5em;
        width: 100%;
      }
    }
    @include g.media(sp) {
      width: auto;
      margin: 0;
      border-top: 1px solid #D8D5D5;
      &:nth-child(3) {
        &::after {
          content: none;
        }
      }
      > a {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
  }
  &__childList {
    margin-top: 10px;
    @include g.media(sp) {
      margin-top: 0;
    }
  }
  &__childItem {
    @include g.media(pc) {
      position: relative;
      margin-bottom: 10px;
      padding-left: 13px;
      &::before {
        content: "";
        position: absolute;
        top: .5em;
        left: 5px;
        width: 3px;
        height: 3px;
        background: g.$color-font;
        border-radius: 50%;
      }
      &.-noDot {
        margin-top: 20px;
        padding-left: 0;
        &::before {
          content: none;
        }
      }
    }
    a {
      font-weight: normal;
    }
    @include g.media(sp) {
      border-top: 1px solid #D8D5D5;
      > a {
        padding-left: 47px;
        font-size: 1.6rem;
      }
    }
  }
}


// 企業・IR情報
.nav-company_ir {
  @include g.media(pc) {
    position: relative;
    display: flex;
    padding-top: 40px;
  }
  @include g.media(tablet) {
    display: block;
    padding-top: 0;
  }
  @include g.media(sp) {
    font-size: 1.6rem;
  }
  &__title {
    display: none;
    @include g.media(sp) {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 100%;
      padding: 12px 15px 12px 30px;
      border-top: 1px solid #D8D5D5;
      appearance: none;
      text-align: left;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        width: 16px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_bottom.svg) no-repeat center;
        background-size: 100% auto;
        transition: all .5s;
      }
      &.-open {
        color: g.$color-main;
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
.nav-recruit,
.nav-rd,
.nav-company,
.nav-newsrelease {
  flex: 1;
  text-align: left;
  padding-right: 10px;
  @include g.media(tablet) {
    padding-right: 0;
  }
  a {
    display: inline-block;
    color: g.$color-font;
    @include g.media(sp) {
      position: relative;
      display: block;
      padding: 12px 15px 12px 47px;
      font-weight: normal;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 17px;
        width: 8px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_right.svg) no-repeat center;
        background-size: 100% auto;
      }
    }
  }
  &__title {
    @include g.media(sp) {
      border-top: 1px solid #D8D5D5;
    }
    > a {
      margin-bottom: 23px;
      @include g.media(sp) {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    &--main {
      @include g.media(pc) {
        > a {
          position: absolute;
          top: 0;
        }
      }
      @include g.media(tablet) {
        > a {
          position: initial;
        }
      }
    }
  }
  &__list {
    @include g.media(tablet) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 25px;
    }
    @include g.media(sp) {
      display: block;
      margin-bottom: 0;
    }
    li {
      @include g.media(pc) {
        margin-bottom: 15px;
      }
      @include g.media(tablet) {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
      @include g.media(sp) {
        border-top: 1px solid #D8D5D5;
        margin-right: 0;
      }
      a {
        font-weight: normal;
      }
    }
  }
}
.nav-company {
  @include g.media(pc) {
    padding-top: 43px;
  }
  @include g.media(tablet) {
    padding-top: 0;
  }
}
.nav-ir {
  flex: 2;
  text-align: left;
  padding-right: 10px;
  @include g.media(tablet) {
    padding-right: 0;
  }
  a {
    display: inline-block;
    color: g.$color-font;
    @include g.media(sp) {
      position: relative;
      display: block;
      padding: 10px 15px 10px 47px;
      font-weight: normal;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 17px;
        width: 8px;
        height: 100%;
        background: url(../img/share/sp-arrow_menu_right.svg) no-repeat center;
        background-size: 100% auto;
      }
      &.js-sp-toggle__btn {
        &::after {
          right: 15px;
          width: 16px;
          background-image: url(../img/share/sp-arrow_menu_bottom.svg);
          transition: all .5s;
        }
        &.-open {
          color: g.$color-main;
          background: #FFF8F8;
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
  &__title {
    @include g.media(sp) {
      border-top: 1px solid #D8D5D5;
    }
    > a {
      margin-bottom: 23px;
      @include g.media(sp) {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }
  &__list {
    @include g.media(pc) {
      display: flex;
      flex-flow: column wrap;
      height: 640px;
    }
    @include g.media(sp) {
      border-top: 1px solid #D8D5D5;
    }
  }
  &__item {
    @include g.media(pc) {
      margin-bottom: 20px;
      padding-right: 15px;
    }
    @include g.media(sp) {
      border-bottom: 1px solid #D8D5D5;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &__listChild {
    @include g.media(pc) {
      margin-top: 10px;
    }
    @include g.media(sp) {
      border-top: 1px solid #D8D5D5;
      &.-open {
        background: #FFF8F8;
      }
    }
    li {
      @include g.media(pc) {
        position: relative;
        margin-bottom: 10px;
        padding-left: 13px;
        &::before {
          content: "";
          position: absolute;
          top: .5em;
          left: 5px;
          width: 3px;
          height: 3px;
          background: g.$color-font;
          border-radius: 50%;
        }
      }
      @include g.media(sp) {
        border-bottom: 1px solid #D8D5D5;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    a {
      font-weight: normal;
      @include g.media(sp) {
        padding-left: 63px;
      }
    }
  }
}

.nav-recruit {
  flex: none;
  a {
    @include g.media(sp) {
      padding-left: 30px;
    }
  }
}

.nav-newsrelease {
  &__title {
    @include g.media(pc) {
      position: absolute;
      top: 420px;
      left: 0;
    }
    @include g.media(tablet) {
      position: initial;
    }
  }
}
