@use "../../global" as g;
// ----------------------------------------------------
//  テキストリストリンク
// ----------------------------------------------------
.textListLink {
  $this: &;
  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 30px;
    @include g.media(sp) {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 30px;
    }
  }
  &__item {
    min-width: auto;
    margin: 0 30px 0 0;
    // text-align: center;
    @include g.media(tablet) {
      margin: 0 20px 0 0;
    }
    @include g.media(sp) {
      width: calc(50% - 2px);
      margin: 0 0 15px;
      text-align: left;
    }
  }
  & &__link {
    padding: 5px 0;
    color: g.$color-font;
    font-weight: bold;
    @include g.media(sp) {
      position: relative;
      font-size: 1.3rem;
      padding-left: 28px;
    }
    &::before {
      content: "";
      padding: 5px 0 5px 35px;
      background: url(../img/share/arrow_circle_right_gray.svg) no-repeat center left;
      @include g.media(sp) {
        position: absolute;
        top: 1px;
        left: 0;
        width: 23px;
        height: 23px;
        padding: 0;
        background-size: 100% auto;
      }
    }
    &:hover {
      color: g.$color-main;
    }
    .blankIcon {
      vertical-align: middle;
      transform: translateY(-2px);
    }
  }
}
