@use "../../global" as g;
// ----------------------------------------------------
//  商品詳細のよくいただくご質問
// ----------------------------------------------------
.products-detailFAQ {
  margin-bottom: 80px;
  &__body {
    margin-bottom: 35px;
  }
  &__note {
    margin-bottom: 40px;
    line-height: 1.4;
  }
}
