@use "../../global" as g;
// ----------------------------------------------------
//  ※マーク付きリスト
// ----------------------------------------------------
.notesList {
  line-height: 1.4;
  &__item {
    text-indent: -1.3em;
    margin-left: 1.3em;
    margin-bottom: 7px;
    font-size: 1.4rem;
    @include g.media(sp) {
      font-size: 1.0rem;
    }
  }
}
