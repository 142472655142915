@use "../../global" as g;
// ----------------------------------------------------
//  下層ページのテキスト主体のコンテンツ
// ----------------------------------------------------
.pageBody {
  padding-bottom: 20px;
  @include g.media(sp) {
    padding-bottom: 35px;
  }
}
