@use "../../global" as g;
// ----------------------------------------------------
//  スクロールすることを伝える
// ----------------------------------------------------
.scrollHint {
  display: none;
  @include g.media(tablet-l) {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    line-height: 1.4;
  }
  &__image {
    width: 55px;
    margin-right: 7px;
  }
  &__text {
    flex: 1;
    font-size: 1.4rem;
  }
}
