@use "../../global" as g;
// -----------------------------------------
// スキップリンク
// -----------------------------------------
.skipLink {
  overflow: hidden;
  background: g.$color-main;
  color: white;
  a {
    display: block;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding: 0;
    &:focus {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      height: auto;
      background: g.$color-main;
    }
  }
}

