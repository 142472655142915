@use "../../global" as g;
// ----------------------------------------------------
//  アレルギー情報検索結果のテーブル
// ----------------------------------------------------
.allergy-resultsTableWrap {
  position: relative;
  margin-bottom: 60px;
  overflow: hidden;
  @include g.media(sp) {
    margin-bottom: 40px;
  }
}
.allergy-resultsTableInner {
  position: relative;
  background-color: #fff;
  overflow-x: auto;
  z-index: 1;
}
.allergy-resultsTableClone {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
  &._left {
    &.-show {
      opacity: 1;
      z-index: 2;
    }
  }
  &._top,
  &._topleft {
    border-bottom: 1px solid #707070;
    &.-fixed {
      position: fixed;
      top: 0;
      bottom: auto;
      opacity: 1;
      z-index: 3;
    }
    &.-absoluteBottom {
      position: absolute;
      top: auto;
      bottom: 1px;
      opacity: 1;
      z-index: 3;
    }
  }
  &._topleft {
    &.-fixed,
    &.-absoluteBottom {
      z-index: 4;
    }
    .allergy-resultsTable {
      width: auto;
    }
  }
}
.allergy-resultsTable {
  width: g.$width-base;
  border: 2px solid #707070;
  line-height: 1.4;
  table-layout: fixed;
  caption {
    overflow: hidden;
    height: 0;
  }
  &__products {
    width: 180px;
    height: 168px;
    @include g.media(sp) {
      width: 6em;
      height: 144px;
    }
  }
  &__image {
    width: 65px;
    img {
      width: 100%;
    }
    @include g.media(sp) {
      width: 55px;
    }
  }
  &__materials {
    background: #FFEAEE;
  }
  &__equivalent {
    background: g.$color-light;
  }
  & &__material {
    width: 1em;
    vertical-align: top;
    font-weight: normal;
    span {
      min-height: 7em;
      writing-mode: vertical-rl;
      text-align: initial;
      @include g.media(ie) {
        writing-mode: horizontal-tb;
      }
    }
    @include g.media(sp) {
      font-size: 1.3rem;
    }
  }
  &__contamination,
  &__note {
    width: 8em;
  }
  th {
    padding: 10px 0;
    border: 1px solid #707070;
  }
  td {
    border: 1px solid #707070;
  }
  thead {
    background: white;
    th {
      border-right-width: 2px;
      @include g.media(sp) {
        font-size: 1.5rem;
      }
      // &.allergy-resultsTable__equivalent,
      // &.allergy-resultsTable__materials {
        // position: relative;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   bottom: -1px;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: #707070;
        // }
      // }
      &.allergy-resultsTable__material {
        border-right-width: 1px;
        @include g.media(sp) {
          padding-right: 3px;
          padding-left: 3px;
        }
        &:nth-child(28),
        &:nth-child(8) {
          border-right-width: 2px;
        }
      }
      &.allergy-resultsTable__note,
      &.allergy-resultsTable__contamination {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: g.$color-light;
      }
    }
    th {
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
      border-right-width: 2px;
      font-weight: normal;
      @include g.media(sp) {
        font-size: 1.3rem;
      }
      a {
        color: g.$color-font;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    td {
      padding: 15px 3px;
      text-align: center;
      @include g.media(sp) {
        font-size: 1.3rem;
      }
      &:nth-child(3),
      &:nth-child(11),
      &.allergy-resultsTable__image,
      &.allergy-resultsTable__contamination,
      &.allergy-resultsTable__note {
        border-left-width: 2px;
      }
      &.allergy-resultsTable__contamination,
      &.allergy-resultsTable__note {
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 1.4rem;
        @include g.media(sp) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
