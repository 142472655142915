@use "../global" as g;

.js-nav__item > button:focus,
.js-nav__item > button.-open,
.js-nav__item > a:focus,
.js-nav__item > a.-open {
  color: g.$color-main;
}

.js-nav__item > button.-open,
.js-nav__item > a.-open {
  background-color: #fff;
  z-index: 1;
  @include g.media(sp) {
    background-color: #FAFAFA;
  }
}

.js-sp-navBody {
  @include g.media(sp) {
    &.-open {
      display: block;
    }
  }
}
