@use "../../global" as g;
// -----------------------------------------
// 日付無しタイトルだけの一覧
// -----------------------------------------
.infoList {
  margin-bottom: 40px;
  border-top: 1px solid #D8D5D5;
  &__item {
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid #D8D5D5;
  }
  &__link {
    position: relative;
    display: flex;
    padding: 5px 40px 5px 0;
    color: g.$color-font;
    line-height: 1.4;
    @include g.media(sp) {
      flex-direction: column;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 100%;
      background: url(../img/share/arrow_circle_right_red.svg) no-repeat center;
      background-size: 100% auto;
      transition: .2s;
    }
    &:hover {
      &::before {
        right: -2px;
      }
    }
  }
  &__time {
    min-width: 11em;
    @include g.media(sp) {
      margin-bottom: 10px;
    }
  }
  &__title {
    flex: 1;
  }
}

