@use "../../global" as g;
// -----------------------------------------
// キャンペーンページの一覧
// -----------------------------------------
.campaign-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -50px;
  &::after {
    content: "";
    display: block;
    width: calc(33.333% - 36px);
    @include g.media(tablet-s) {
      width: calc(33.333% - 20px);
    }
    @include g.media(tablet-s) {
      content: none;
    }
  }
  @include g.media(sp) {
    display: block;
    margin-bottom: 0;
  }
  &__item {
    width: calc(33.333% - 36px);
    margin-bottom: 55px;
    @include g.media(tablet-l) {
      width: calc(33.333% - 20px);
    }
    @include g.media(tablet-s) {
      width: calc(50% - 15px);
    }
    @include g.media(sp) {
      width: auto;
      margin-bottom: 35px;
    }
  }
  & &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    color: g.$color-font;
    line-height: 1.6;
    height: 100%;
    &:hover {
      color: g.$color-font;
      text-decoration: none;
    }
  }
  &__image {
    order: 1;
    margin: 0 auto 20px;
    text-align: center;
    @include g.media(sp) {
      margin-bottom: 15px;
    }
  }
  &__categories {
    order: 2;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    font-size: 1.4rem;
    @include g.media(sp) {
      font-size: 1.1rem;
    }
  }
  &__category {
    margin-right: 20px;
    margin-bottom: 15px;
    padding: 2px 10px;
    color: white;
    background: g.$color-dark;
    @include g.media(sp) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  &__title {
    order: 3;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 2rem;
    @include g.media(sp) {
      margin-bottom: 10px;
      font-size: 1.5rem;
    }
    .blankIcon {
      vertical-align: baseline;
      transform: translateY(0px);
      @include g.media(sp) {
        width: 15px;
      }
    }
  }
  &__text {
    order: 4;
    margin-bottom: 4.2em;
    font-size: 1.8rem;
    @include g.media(sp) {
      margin-bottom: 20px;
      font-size: 1.4rem;
    }
  }
  &__schedule {
    order: 5;
    font-size: 1.6rem;
    position: absolute;
    bottom: 0;
    left: 0;
    @include g.media(sp) {
      position: static;
      font-size: 1.2rem;
    }
    &Item {
      b {
        font-weight: normal;
        color: g.$color-main;
      }
    }
  }
}

