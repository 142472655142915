@use "../../global" as g;
// -----------------------------------------
// セクション
// -----------------------------------------
.section {
  margin-bottom: 130px;
  @include g.media(sp) {
    margin-bottom: 60px;
  }
}

.subSection {
  margin-bottom: 80px;
  @include g.media(sp) {
    margin-bottom: 50px;
  }
}
