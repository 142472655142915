@use "../../global" as g;
// ----------------------------------------------------
//  ローカルナビ
// ----------------------------------------------------
.localNav {
  $this: &;
  margin: 0 auto 60px;
  &__list {
    &.-main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 35px;
      padding: 10px 0;
      @include g.media(sp) {
        margin: 0 -10px 25px;
        padding: 13px 0;
      }
      @include g.media(w320) {
        padding: 13px 0;
      }
      #{$this} {
        &__item {
          flex: 1;
          position: relative;
          margin: 0;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -1px;
            width: 1px;
            height: 100%;
            margin: 0;
            padding: 0;
            background: g.$color-dark;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            margin: 0;
            padding: 0;
            background: g.$color-dark;
          }
          @include g.media(sp) {
            width: auto;
            flex: 1;
            &::after {
              width: 1px;
            }
            &:first-child {
              &::before {
                width: 0;
              }
            }
            &:last-child {
              &::after {
                width: 0;
              }
            }
          }
        }
        &__link {
          display: block;
          padding: 12px 10px;
          color: g.$color-font;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.4;
          &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            width: 0;
            height: 4px;
            background: g.$color-main;
            border-radius: 10px;
            transition: all .5s;
          }
          @include g.media(sp) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 5px 3px;
            font-size: 1.4rem;
            line-height: 1.0;
          }
          &.-current,
          &:hover {
            color: g.$color-main;
            text-decoration: none;
            &::before {
              left: 8%;
              width: calc(100% - 16%);
            }
          }
        }
      }
    }
  }
  // グレー背景角丸のボタン
  &__list {
    &.-btn {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 0 -10px 15px;
      padding: 0;
      @include g.media(sp) {
        justify-content: center;
        margin: 0 -20px 25px;
      }
      #{$this} {
        &__item {
          min-width: 160px;
          max-width: 224px;
          margin: 0 10px 20px;
          text-align: center;
          @include g.media(pc) {
            flex: 1;
          }
          @include g.media(sp) {
            margin: 0 5px 10px;
            min-width: calc(33.333% - 20px);
            max-width: none;
          }
          @include g.media(w320) {
            margin: 0 5px 10px;
            min-width: calc(50% - 20px);
          }
        }
        &__link {
          box-sizing: border-box;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 12px 10px;
          background: g.$color-dark;
          border-radius: 100px;
          color: white;
          font-size: 1.6rem;
          font-weight: bold;
          @include g.media(sp) {
            font-size: 1.3rem;
            line-height: 1;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 20px;
            width: 16px;
            height: 100%;
            background: url(../img/share/arrow_right_white.svg) no-repeat center;
            background-size: 100% auto;
            transition: right .3s;
            @include g.media(sp) {
              right: 10px;
              width: 10px;
            }
          }
          &[href^="#"] {
            &::after {
              width: 20px;
              background-image: url(../img/share/arrow_bottom_white.svg);
            }
          }
          &:hover {
            color: white;
            background: g.$color-red;
            text-decoration: none;
            &::after {
              right: 18px;
            }
          }
          &.-current {
            color: white;
            background: g.$color-red;
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
  // マーカー＋テキストのローカルナビ
  &__list {
    &.-text {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 35px 5px 0;
      @include g.media(tablet) {
        margin: 0 35px 5px 0;
      }
      @include g.media(sp) {
        margin: 0;
      }
      #{$this} {
        &__item {
          width: auto;
          margin: 0 50px 15px 0;
          @include g.media(tablet) {
            margin-right: 30px;
          }
          @include g.media(tablet-s) {
            margin-right: 20px;
          }
          @include g.media(sp) {
            width: calc(50% - 2px);
            margin: 0 0 15px;
          }
        }
        &__link {
          position: relative;
          padding: 5px 0;
          background: none;
          color: g.$color-font;
          border-radius: 0;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.4;
          @include g.media(sp) {
            display: inline-block;
            padding-left: 28px;
            font-size: 1.3rem;
            text-align: left;
          }
          &::after {
            content: none;
          }
          &::before {
            content: "";
            padding: 5px 0 5px 35px;
            background: url(../img/share/arrow_circle_right_red.svg) no-repeat center left;
            @include g.media(sp) {
              position: absolute;
              top: -4px;
              left: 0;
              width: 23px;
              height: 23px;
              padding-left: 0;
              background-size: 100% auto;
            }
          }
          &[href^="#"] {
            &::before {
              background-image: url(../img/share/arrow_circle_bottom_red.svg);
            }
          }
          &.-current,
          &:hover {
            color: g.$color-main;
            text-decoration: none;
          }
          &.-current {
            &::before {
              background-image: url(../img/share/arrow_circle_bottom_red.svg);
            }
          }
        }
      }
    }
    &.-bottom {
      #{$this} {
        &__link {
          &[href^="#"] {
            &::before {
              background-image: url(../img/share/arrow_circle_top_red.svg);
            }
          }
          &.-current {
            &::before {
              background-image: url(../img/share/arrow_circle_top_red.svg);
            }
          }
        }
      }
    }
    &.-main.-col6 {
      gap: 35px 0;
      justify-content: flex-start;
      @include g.media(sp) {
        margin-left: -16px;
        margin-right: -15px;
        gap: 20px 0;
      }
      #{$this} {
        &__item {
          width: calc(100% / 6);
          flex: none;
          @include g.media(sp) {
            width: calc(100% / 5);
            flex: none;
          }
          @include g.media(sp-s) {
            width: calc(100% / 4);
          }
          &:first-child {
            &::before {
              width: 1px;
            }
          }
          &:last-child {
            &::after {
              width: 1px;
            }
          }
        }
      }
    }
    &.-sp-row {
      @include g.media(sp) {
        justify-content: flex-start;
        margin-left: -15px;
        #{$this} {
          &__item {
            width: auto;
            margin: 0 0 15px 15px;
          }
        }
      }
    }
    &.-sp-row-center {
      @include g.media(sp) {
        justify-content: center;
        // margin-left: -15px;
        #{$this} {
          &__item {
            width: auto;
            margin: 0 12px 15px;
          }
        }
      }
    }
  }
}
