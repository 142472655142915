@use "../global" as g;




// splideの見た目変更
.splide {
  margin-left: -30px;
  margin-right: -30px;
  @include g.media(sp) {
    margin-left: 0;
    margin-right: 0;
  }
  &__track {
    margin-bottom: 15px;
  }
  &__slide {
    padding: 5px 30px;
    @include g.media(sp) {
      padding: 0;
    }
  }
  &__arrows {
    display: flex;
    justify-content: center;
    padding: 0 30px;
    @include g.media(sp) {
      padding: 0;
      position: relative;
    }
  }
  &__arrow {
    // position: absolute;
    // bottom: 2px;
    font-size: 0;
    line-height: 0;
    width: 16px;
    min-width: 16px;
    height: 26px;
    &--prev {
      margin-right: 35px;
      background: url(../img/share/arrow_left_gray.svg) no-repeat center;
      background-size: 100% auto;
      @include g.media(sp) {
        margin-right: 0;
      }
    }
    &--next {
      margin-left: 35px;
      background: url(../img/share/arrow_right_gray.svg) no-repeat center;
      background-size: 100% auto;
      @include g.media(sp) {
        margin-left: 0;
      }
    }
    &[disabled] {
      opacity: .3;
    }
  }
  &__pagination {
    @include g.media(sp) {
      padding: 0 30px;
      flex: 1;
    }
    li {
      display: flex;
      box-sizing: border-box;
      margin: 0 4px;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &__page {
      width: 16px;
      height: 16px;
      background: #666 url(../img/share/slide_bullet.svg) no-repeat center;
      background-size: 8px auto;
      border-radius: 50%;
      &.is-active {
        width: 24px;
        height: 24px;
        background-image: url(../img/share/slide_bullet_active.svg);
        background-color: g.$color-main;
        background-size: 11px auto;
      }
    }
  }
  &__controlBtn {
    width: 24px;
    height: 24px;
    margin-left: 50px;
    background: url(../img/share/btn_pause.svg) no-repeat center;
    background-size: 100% auto;
    font-size: 0;
    line-height: 0;
    &.-play {
      background-image: url(../img/share/btn_play.svg);
    }
    @include g.media(sp) {
      position: absolute;
      top: -1px;
      right: 25px;
      width: 28px;
      height: 28px;
      margin-left: 0;
    }
  }
  &:not(.is-overflow) {
    .splide__list {
      justify-content: center;
    }
  }
}
