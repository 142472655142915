@use "../../global" as g;
// -----------------------------------------
// リンクページの一覧
// -----------------------------------------
.linkList {
  margin-bottom: 40px;
  @include g.media(sp-m) {
    margin-top: -15px;
  }
  &__item {
    display: flex;
    padding: 15px 0 14px;
    border-bottom: 1px solid #D8D5D5;
    @include g.media(sp-m) {
      flex-direction: column;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  &__link {
    color: g.$color-font;
  }
  &__title {
    width: 28%;
    padding-right: 25px;
    font-weight: bold;
    @include g.media(sp-m) {
      width: auto;
    }
  }
  &__text {
    flex: 1;
  }
}

