@use "../global" as g;
// ----------------------------------------------------
//  SPのMENUボタン
// ----------------------------------------------------
.sp-navBtn {
  display: none;
  @include g.media(sp) {
    display: block;
    position: absolute;
    top: 11px;
    right: 15px;
    width: 30px;
    height: auto;
    padding-top: 18px;
    font-size: 1rem;
    color: #747474;
    > span {
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #747474;
        border-radius: 50px;
        transition: all .5s;
      }
      &::after {
        top: 12px;
      }
      > span {
        &::before {
          content: "";
          position: absolute;
          top: 6px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #747474;
          border-radius: 50px;
          transition: all .5s;
        }
      }
    }
    &.-open {
      > span {
        &::before,
        &::after {
          width: 22px;
        }
        &::before {
          transform: rotate(45deg) translate(8px, 3px);
        }
        &::after {
          transform: rotate(-45deg) translate(5px, -1px);
        }
        > span {
          &::before {
            left: 10px;
            opacity: 0;
          }
        }
      }
    }
  }
}
