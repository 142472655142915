@use "../../global" as g;
// -----------------------------------------
// トップページのキャンペーン
// -----------------------------------------
.top-campaign {
  &__body {
    overflow: hidden;
    max-width: g.$width-base - 112px;
    margin: 0 auto 55px;
    padding: 2px;
  }
  @include g.media(sp) {
  }
}

