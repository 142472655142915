@use "../../global" as g;
// ----------------------------------------------------
//  しましまな定義リスト
// ----------------------------------------------------
.stripeList {
  margin-bottom: 10px;
  @include g.media(sp) {
    margin: 0 -15px 10px;
  }
  &__item {
    display: flex;
    padding: 17px 40px;
    background: g.$color-light;
    &:nth-child(even) {
      background: white;
    }
    @include g.media(sp) {
      flex-direction: column;
      margin-bottom: 15px;
      padding: 0;
      background: white;
    }
  }
  &__title {
    min-width: 280px;
    font-weight: bold;
    @include g.media(sp) {
      padding: 10px 15px;
      background: g.$color-light;
      font-size: 1.3rem;
    }
  }
  &__body {
    flex: 1;
    @include g.media(sp) {
      padding: 10px 30px 0;
      font-size: 1.3rem;
    }
    @include g.media(w320) {
      padding: 10px 15px 0;
    }
  }
}
