@use "../../global" as g;

// font-weight

.fw-b,
.fw-bold {
  font-weight: bold;
}

.fw-n,
.fw-normal {
  font-weight: normal;
}
