@use "../../global" as g;
// -----------------------------------------
// 別窓アイコン
// -----------------------------------------
.blankIcon {
  margin-left: 10px;
  transform: translateY(2px);
  @include g.media(sp) {
    width: 16px;
    height: 16px;
  }
}

.sitemap__list .pdfIcon {
  margin-left: 8px;
  transform: translateY(4px);
  @include g.media(sp) {
    width: auto;
    height: 16px;
    margin-left: 4px;
    transform: translateY(2px);
  }
}

