@use "../../global" as g;
// -----------------------------------------
// 下層ページ上部の新着やピックアップエリア
// -----------------------------------------
.pageHero {
  $this: &;
  margin: 80px calc(-50vw + 50%);
  padding: 0 30px;
  background: g.$color-light;
  @include g.media(sp) {
    margin: 60px -15px;
    padding: 0 15px;
  }
  &__inner {
    max-width: g.$width-base + 4px;
    margin: auto;
    padding: 60px 0;
    @include g.media(sp) {
      padding: 40px 0;
    }
  }
  &__title {
    margin-bottom: 45px;
    font-size: 3.4rem;
    line-height: 1.4;
    text-align: center;
    font-weight: bold;
    color: g.$color-main;
    @include g.media(sp) {
      margin-bottom: 30px;
      font-size: 3rem;
    }
    @include g.media(sp-m) {
      font-size: 2.1rem;
    }
  }
  &__main {
    // overflow-y: hidden;
    padding: 2px;
  }
  &__footer {
    margin-top: 50px;
    margin-bottom: -1.5em;
  }
  &.-newCM,
  &.-newProducts {
    @include g.media(sp) {
      padding-left: 0;
      padding-right: 0;
      .splide__arrows {
        padding: 0 15px;
      }
    }
    #{$this} {
      &__inner {
        max-width: 1174px;
        padding-left: 30px;
        padding-right: 30px;
        @include g.media(sp) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

