@use "../../global" as g;
// -----------------------------------------
// 商品情報のキーワード検索
// -----------------------------------------
.products-searchKeywords {
  position: relative;
  box-sizing: border-box;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  border: 1px solid #8E8E8E;
  border-radius: 100px;
  input[type="text"] {
    box-sizing: border-box;
    height: 48px;
    width: calc(100% - 85px);
    padding: 10px 0 10px 35px;
    border: 0;
    border-radius: 100px 0 0 100px;
    font-size: 1.8rem;
    @include g.media(sp) {
      padding-left: 15px;
      font-size: 1.6rem;
    }
  }
  input[type="submit"] {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 85px;
    height: 50px;
    padding: 0;
    border: 0;
    background: g.$color-main url(../img/products/btn_search.svg) no-repeat 42% center;
    border-radius: 0 100px 100px 0;
    color: white;
    font-size: 0;
    line-height: 0;
    @include g.media(sp) {
      width: 53px;
      background-position: 36% 45%;
    }
  }
}

