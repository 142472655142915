@use "../../global" as g;
// ----------------------------------------------------
//  今月の天使のレシピカレンダー壁紙プレゼント
// ----------------------------------------------------
.products-wallpaperBox {
  margin: 0 calc(-50vw + 50%);
  padding: 25px 0 60px;
  background: g.$color-light;
  @include g.media(tablet) {
    margin: 0 -30px;
    padding: 25px 0 40px;
  }
  &__inner {
    box-sizing: border-box;
    max-width: g.$width-base;
    margin: 0 auto;
    padding: 0 60px;
    @include g.media(tablet) {
      padding: 0 35px;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    @include g.media(sp) {
      display: block;
    }
  }
  &__image {
    box-sizing: border-box;
    width: 520px;
    padding: 18px;
    margin-right: 40px;
    background: white;
    @include g.media(tablet) {
      margin-right: 30px;
      width: auto;
      flex: 1;
    }
    @include g.media(sp) {
      max-width: 460px;
      margin: 0 auto 15px;
      padding: 10px;
    }
    img {
      width: 100%;
    }
  }
  &__text {
    flex: 1;
  }
  &__btn {
    @include g.media(sp) {
      text-align: center;
    }
  }
}
