@use "../global" as g;
/*----------------------------------------------------
  base (styling element directly contain reset style)
----------------------------------------------------*/
html {
  font-size: 62.5%;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background:g.$color-body;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: g.$font-base;
  font-weight: 500;
  color: g.$color-font;
  line-height: 1.8;
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);

  // MQPackerのソート制御
  @each $key, $value in g.$breakpoints {
    @media #{$value} {
      margin: 0;
    }
  }
  @include g.media(sp) {
    min-width: 0;
    font-size: 1.6rem;
    overflow-x: hidden;
  }
}

a {
  color: g.$color-link;
  text-decoration: none;
  transition: none .3s linear;
  transition-property: background, color;
  img {
    transition: opacity .3s linear;
  }
  &:hover {
    color: g.$color-link_hover;
    @include g.media(pc) {
      text-decoration: underline;
      img {
        opacity: .85;
        &.blankIcon {
          opacity: 1;
        }
      }
    }
  }
}

svg,
img {
  max-width: 100%;
  vertical-align: top;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

select,
button,
[type="reset"],
[type="button"],
[type="submit"] {
  cursor: pointer;
}

textarea,
button,
[type="reset"],
[type="button"],
[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea,
select,
button,
input {
  font-family: g.$font-base;
  border-radius: 0;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6,
p, ul, ol, table, dl, dd {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: inherit;
}

table {
  border-collapse: collapse;
}

.small,
small {
  font-size: 80%;
}

img {
  @include g.media(tablet) {
    max-width: 100%;
    height: auto;
  }
}

.p,
p {
  margin-bottom: 1.5em;
  @include g.media(sp) {
  }
}

ol {
  margin-bottom: 1.5em;
  > li {
    list-style: decimal;
    margin-left: 2em;
    margin-bottom: 5px;
  }
}

iframe {
  border: 0;
}

em {
  font-style: normal;
}

hr {
  clear: both;
  display: block;
  width: 100%;
  height: 0;
  margin: 30px auto;
  border: 0;
  border-bottom: 1px dotted #ccc;
}

::selection {
  background-color: g.$color-main;
  color: #fff;
}

