@use "../../global" as g;
// -----------------------------------------
// 応募条件の絞り込み
// -----------------------------------------
.refinedSearch {
  margin: 0 auto 80px;
  padding: 25px 40px 20px;
  border: 2px solid g.$color-gray;
  @include g.media(sp) {
    margin-bottom: 40px;
    padding: 10px 15px 5px;
  }
  &__title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 2rem;
    @include g.media(sp) {
      font-size: 1.4rem;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    margin-right: 55px;
    margin-bottom: 5px;
    &:last-child {
      margin-right: 0;
    }
    @include g.media(sp) {
      margin-right: 25px;
    }
    label {
      display: flex;
      align-items: center;
    }
    input[type="radio"] {
      transform: scale(1.2);
      @include g.media(sp) {
        transform: scale(1);
      }
    }
    span {
      padding-left: 10px;
      @include g.media(sp) {
        padding-left: 7px;
        font-size: 1.3rem;
      }
    }
  }
}

