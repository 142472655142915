@use "../../global" as g;
// -----------------------------------------
// ソーシャルメディア公式アカウント
// -----------------------------------------
.socialList {
  $this: &;
  display: grid;
  gap: 40px 80px;
  grid-template-columns: 1fr 1fr;
  @include g.media(tablet) {
    gap: 40px 30px;
  }
  @include g.media(sp) {
    grid-template-columns: 1fr;
    gap: 30px 30px;
  }
  &__item {
  }
  & &__link {
    display: flex;
    color: g.$color-font;
    &:hover {
      text-decoration: none;
      #{$this} {
        &__image {
          border-color: g.$color-main;
        }
        &__title {
          text-decoration: underline;
        }
      }
    }
  }
  &__image {
    align-self: flex-start;
    width: 180px;
    margin-right: 20px;
    border: 1px solid #E2E2E2;
    transition: border-color .3s;
    @include g.media(tablet) {
      width: 150px;
    }
    @include g.media(sp-m) {
      width: 106px;
      margin-right: 10px;
    }
  }
  &__text {
    order: 2;
    flex: 1;
  }
  &__title {
    margin-bottom: 5px;
    font-size: 2rem;
    font-weight: bold;
    @include g.media(tablet) {
      font-size: 1.8rem;
    }
    @include g.media(sp-m) {
      margin-bottom: 3px;
      font-size: 1.5rem;
    }
    img {
      vertical-align: baseline;
    }
  }
  &__summary {
    @include g.media(tablet) {
      font-size: 1.5rem;
    }
    @include g.media(sp-m) {
      font-size: 1.4rem;
    }
  }
}

