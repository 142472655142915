@use "../../global" as g;
// ----------------------------------------------------
//  アレルギー情報検索結果
// ----------------------------------------------------
.allergy-results {
  margin-bottom: 80px;
  @include g.media(sp) {
    margin-bottom: 40px;
  }
  &__header {
    margin-bottom: 60px;
    @include g.media(sp) {
      margin-bottom: 40px;
    }

    .guide {
      text-indent: -1em;
      padding-left: 1em;
      font-size: 1.4rem;
    }
  }
  &__main {
    margin-bottom: 80px;
    @include g.media(sp) {
      margin-bottom: 60px;
    }
  }
  &__list {
    margin-bottom: 25px;
    font-size: 3.2rem;
    line-height: 1.4;
    li {
      margin-bottom: 10px;
    }
    strong {
      color: g.$color-pink;
    }
    @include g.media(sp) {
      font-size: 1.7rem;
      li {
        margin-bottom: 5px;
      }
    }
  }
  &__text {
    font-size: 1.8rem;
    @include g.media(sp) {
      font-size: 1.4rem;
    }
  }
  &__btnList {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    @include g.media(sp) {
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
    }
    &Item {
      width: 340px;
      margin: 0 20px;
      line-height: 1.5;
      @include g.media(sp) {
        margin: 0 0 20px;
        width: calc(50% - 10px);
        &:first-child {
          width: 100%;
        }
      }
      @include g.media(sp-s) {
        width: calc(50% - 5px);
        &:first-child {
          width: 100%;
        }
      }
    }
    &ItemLink {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3.5em;
      padding: 5px 10px;
      background: g.$color-main;
      border: 3px solid g.$color-main;
      border-radius: 100px;
      color: white;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      &:link,
      &:active,
      &:visited {
        color: white;
      }
      @include g.media(sp) {
        min-height: 3em;
        font-size: 1.4rem;
      }
      @include g.media(sp-s) {
        font-size: 1.3rem;
      }
      @include g.media(w320) {
        font-size: 1.2rem;
      }
      &:hover {
        text-decoration: none;
        background: white;
        color: g.$color-main;
      }
      &.-back {
        background: g.$color-blue;
        border-color: g.$color-blue;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 25px;
          width: 12px;
          height: 100%;
          background: url(../img/share/arrow_right_blue.svg) no-repeat center;
          background-size: 100% auto;
          filter: grayscale(100) brightness(100);
          transform: rotate(-180deg);
          @include g.media(sp) {
            right: 13px;
          }
        }
        &:hover {
          background: white;
          color: g.$color-blue;
          &::after {
            filter: brightness(1);
          }
        }
      }
    }
    &ItemIcon {
      margin-left: 25px;
      @include g.media(sp) {
        margin-left: 10px;
        img {
          width: 22px;
        }
      }
      @include g.media(w320) {
        margin-left: 5px;
        img {
          width: 18px;
        }
      }
    }
    &Note {
      display: block;
      margin-top: 8px;
      text-align: center;
    }
  }
  // ページ送り
  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    @include g.media(sp) {
      margin-top: 30px;
    }
    &List {
      &.-next,
      &.-prev {
        a {
          display: block;
          width: 40px;
          height: 40px;
          border: 1px solid g.$color-gray;
          font-size: 0;
          line-height: 0;
          transition: border-color .3s;
          &:hover {
            border-color: g.$color-main;
          }
        }
      }
      &.-prev {
        a {
          background: url(../img/share/arrow_left_black.svg) no-repeat center;
          background-size: 12px auto;
        }
      }
      &.-next {
        a {
          background: url(../img/share/arrow_right_black.svg) no-repeat center;
          background-size: 12px auto;
        }
      }
      &.-current {
        margin: 0 45px;
      }
    }
  }
}
