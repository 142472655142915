@use "../../global" as g;
// ----------------------------------------------------
//  商品詳細のヘッダー
// ----------------------------------------------------
.products-detailHeader {
  $this: &;
  margin-bottom: 55px;
  @include g.media(sp) {
    margin-bottom: 25px;
  }
  &__shoulder {
    display: block;
    margin-bottom: 10px;
    font-size: 2.8rem;
    font-weight: normal;
    @include g.media(sp) {
      font-size: 1.7rem;
    }
  }
  &__title {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1.4;
    @include g.media(sp) {
      font-size: 2.0rem;
    }
  }
}
