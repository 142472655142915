// ===================================================================
// 変数色々
// width, font, z-index, breakpoints, color
// ===================================================================

// -----------------------------------------
// 幅関係の設定
// -----------------------------------------
// 全体
$width-base: 1200px;

// 最大幅
$width-max: 1280px;

// 最小幅
$width-min: 0;

// メインエリアの幅
$width-main: 1024px;

// サイドバーの幅
$width-side: 240px;



// -----------------------------------------
// フォント関係の設定
// -----------------------------------------
// ベースフォント
$font-base: "游ゴシック", "Yu Gothic", "YuGothic", "メイリオ", "Meiryo", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Verdana, Arial, sans-serif;

// 明朝
$font-mincho: "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 Pro W6", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;

// Webfont
$font-noto: 'Noto Sans Japanese', $font-base;




// -----------------------------------------
// z-index 管理
// -----------------------------------------
$layer: (
  nav : 999,
  newIcon : 5,
  default : 1,
);


// -----------------------------------------
// ブレイクポイントの設定
// -----------------------------------------
$breakpoints: (
  pc:        "screen and (min-width: 769px)",
  tablet-l:  "screen and (max-width: 1260px)",
  tablet:    "screen and (max-width: 1024px)",
  tablet-s:  "screen and (max-width: 940px)",
  sp:        "screen and (max-width: 768px)",
  sp-m:      "screen and (max-width: 600px)",
  sp-s:      "screen and (max-width: 460px)",
  w320:      "screen and (max-width: 340px)",
  ie:        "all and (-ms-high-contrast:none)",
) !default;



// -----------------------------------------
// 色関係の設定
// -----------------------------------------
// サイトカラー
$color-body: white;
$color-base: white;
$color-main: #b6002a;
$color-sub: #d62a2a;

$color-gray: #E2E2E2;
$color-dark: #666;
$color-light: #F7F7F7;
$color-red: #b6002a;
$color-blue: #003F9A;
$color-pink: #C52750;

// フォントカラー
$color-font: #000;

// リンクカラー
$color-link: #b6002a;
$color-link_visited: #b6002a;
$color-link_hover: #b6002a;
$color-link_active: #b6002a;

