@use "../global" as g;
// ----------------------------------------------------
//  ヘッダー
// ----------------------------------------------------
.header {
  line-height: 1.2;
  background: #fff;
  &__inner {
    display: flex;
    align-items: flex-end;
    max-width: g.$width-base;
    margin: 0 auto;
    padding: 20px 30px 18px;
    @include g.media(tablet) {
      justify-content: center;
    }
    @include g.media(sp) {
      box-sizing: border-box;
      display: block;
      height: 50px;
      padding: 13px 15px;
    }
  }
}

