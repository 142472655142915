@use "../../global" as g;
// -----------------------------------------
// 緊急・重要なお知らせ
// -----------------------------------------
.emergencyInfo {
  padding-top: 5px;
  padding-bottom: 40px;
  @include g.media(sp) {
    margin-left: 45px;
    margin-right: 45px;
    padding-top: 0;
    padding-bottom: 30px;
  }
  &__txt {
    display: inline-block;
    position: relative;
    &::after,
    &::before {
      content: "";
      width: 28px;
      height: 25px;
      display: block;
      background: url(../img/icon_emergency.png) no-repeat center top;
      position: absolute;
      top: -2px;
    }
    &::before {
      left: -40px;
    }
    &::after {
      right: -40px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

