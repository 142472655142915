@use "../global" as g;
// ----------------------------------------------------
//  ヘッダーのロゴ
// ----------------------------------------------------
.logo {
  margin-right: auto;
  &__item {
    margin: 0;
  }
  @include g.media(tablet) {
    position: absolute;
    top: 20px;
    left: 30px;
    margin: 0;
    &__item {
      width: 220px;
    }
  }
  @include g.media(sp) {
    position: static;
    &__item {
      width: 175px;
    }
  }
}

