@use "../../global" as g;
// -----------------------------------------
// トップページの企業情報
// -----------------------------------------
.top-company {
  &__list {
    display: flex;
    @include g.media(tablet-s) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @include g.media(sp) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &Item {
      margin-left: 24px;
      @include g.media(tablet) {
        margin-left: 20px;
      }
      @include g.media(tablet-s) {
        width: auto;
        margin: 0 10px;
        margin-bottom: 20px;
        text-align: center;
      }
      @include g.media(sp) {
        width: calc(33.3333% - 11px);
        margin: 0 0 20px;
      }
      @include g.media(sp-m) {
        width: calc(50% - 7px);
      }
      &:first-child {
        margin-left: 0;
      }
    }
    &Link {
      display: inline-block;
      color: g.$color-font;
      text-align: center;
      &:hover {
        text-decoration: none;
      }
    }
    &Text {
      display: block;
      margin-top: 15px;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.4;
      @include g.media(tablet-l) {
        font-size: 1.8rem;
      }
      @include g.media(tablet) {
        font-size: 1.6rem;
      }
      @include g.media(tablet-s) {
        font-size: 1.5rem;
      }
      @include g.media(sp) {
        margin-top: 10px;
      }
    }
    &Image {
      height: auto;
      @include g.media(sp) {
        width: 100%;
      }
    }
  }
}

