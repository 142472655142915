@use "../../global" as g;
// -----------------------------------------
// iframeの動画
// -----------------------------------------
.movie-iframe {
  margin: 0 auto 30px;
  text-align: center;
  iframe {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    aspect-ratio: 64 / 36;
    text-align: left;
  }
}

