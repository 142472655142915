@use "../../global" as g;
// -----------------------------------------
// 画像のコピーガード
// -----------------------------------------
img.protect {
  position: relative;
  pointer-events: none;
}
.protect {
  img {
    pointer-events: none;
  }
}

@media print {
  img.protect {
    visibility: hidden;
  }
  .protect {
    img {
      visibility: hidden;
    }
  }
}
