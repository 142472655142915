@use "../../global" as g;
// -----------------------------------------
// トップページのメインビジュアル
// -----------------------------------------
.top-hero {
  margin-bottom: 150px;
  @include g.media(sp) {
    margin-bottom: 90px;
  }
  .splide {
    margin: 0;
  }
  &__link {
    display: block;
    position: relative;
    &:focus {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 2px solid #000;
      }
    }
  }
  & &__item {
    padding: 0;
    text-align: center;
  }
  & &__arrows {
    @include g.media(sp) {
      padding: 0 15px;
      .splide__pagination {
        flex: none
      }
    }
  }
}

