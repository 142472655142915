@use "../../global" as g;
// ----------------------------------------------------
//  JS
// ----------------------------------------------------
.js-sp-toggle {
  @include g.media(sp) {
    &__body {
      display: none;
      &.-open {
        display: block;
      }
    }
  }
}
