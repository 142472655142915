@use "../../global" as g;
// ----------------------------------------------------
//  アレルギー情報検索
// ----------------------------------------------------
.allergy-search {
  $this: &;
  margin: 0 calc(-50vw + 50%) 80px;
  padding: 70px 60px 90px;
  background: g.$color-light;
  @include g.media(tablet) {
    margin: 0 -30px 80px;
    padding: 25px 30px 50px;
  }
  @include g.media(sp) {
    margin-bottom: 40px;
  }
  &__inner {
    box-sizing: border-box;
    max-width: g.$width-base;
    margin: 0 auto;
    padding: 0;
  }
  &__body {
    counter-reset: num;
    margin-bottom: 70px;
    @include g.media(sp) {
      margin-bottom: 30px;
    }
  }
  &__lead {
    margin-bottom: 15px;
    font-size: 3.2rem;
    b,
    strong {
      color: g.$color-pink;
    }
    @include g.media(tablet-s) {
      font-size: 2.6rem;
    }
    @include g.media(sp) {
      font-size: 2.2rem;
    }
    @include g.media(sp-s) {
      font-size: 1.8rem;
    }
  }
  &__title {
    position: relative;
    padding-left: 60px;
    @include g.media(sp) {
      padding-left: 43px;
    }
    .ordinal-indicator {
      position: absolute;
      top: 1px;
      left: 0;
      width: 40px;
      height: 40px;
      background: g.$color-main;
      color: white;
      text-align: center;
      line-height: 40px;
      font-size: 2rem;
      @include g.media(sp) {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 1.4rem;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    @include g.media(tablet-l) {
      margin-left: -10px;
    }
    @include g.media(sp) {
      margin-top: -10px;
    }
    &Item {
      position: relative;
      box-sizing: border-box;
      width: calc((100% / 7) - 20px);
      min-height: 4em;
      margin-left: 20px;
      margin-bottom: 20px;
      background: white;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.4;
      @include g.media(tablet-l) {
        width: calc((100% / 6) - 10px);
        margin-left: 10px;
        margin-bottom: 10px;
        font-size: 1.7rem;
      }
      @include g.media(tablet) {
        font-size: 1.6rem;
      }
      @include g.media(tablet-s) {
        width: calc((100% / 5) - 10px);
        font-size: 2.05vw;
      }
      @include g.media(sp) {
        width: calc((100% / 4) - 10px);
        font-size: 1.4rem;
      }
      @include g.media(sp-m) {
        width: calc((100% / 3) - 10px);
      }
      @include g.media(sp-s) {
        width: calc((100% / 2) - 10px);
      }
      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: -5px;
        z-index: 0;
        opacity: 1;
        width: 0;
        height: 0;
        outline: none;
        &:checked + label {
          border-color: g.$color-pink;
          &::after {
            opacity: 1;
          }
        }
        &:focus-visible + label {
          border-color: g.$color-blue;
        }
      }
      label {
        box-sizing: border-box;
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 7px 10px 5px 52px;
        border: 3px solid g.$color-gray;
        transition: border-color .5s;
        @include g.media(tablet-s) {
          padding-left: 42px;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 15px;
          content: '';
          display: block;
          height: 24px;
          width: 24px;
          margin-top: -14px;
          background: #fff;
          border: 2px solid #D8D5D5;
          @include g.media(tablet-s) {
            left: 10px;
            height: 20px;
            width: 20px;
            margin-top: -12px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 21px;
          display: block;
          height: 15px;
          width: 11px;
          margin-top: -12px;
          border-right: 4px solid g.$color-pink;
          border-bottom: 4px solid g.$color-pink;
          opacity: 0;
          transform: rotate(35deg);
          transition: all .5s;
          @include g.media(tablet-s) {
            left: 15px;
            height: 13px;
            width: 9px;
            margin-top: -11px;
          }
        }
        &:hover {
          cursor: pointer;
          border-color: rgba(g.$color-pink, .6);
        }
        &.allergy-search__inputCircle {
          &::before {
            border-radius: 100%;
          }
          &::after {
            border: none;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            background: g.$color-pink;
            border-radius: 100%;
            transform: rotate(0);
            @include g.media(tablet-s) {
              // left: 15px;
              height: 14px;
              width: 14px;
              margin-top: -7px;
            }
          }
        }
      }
    }
    &.-col5 {
      #{$this} {
        &__listItem {
          width: calc((100% / 5) - 20px);
          @include g.media(tablet-l) {
            width: calc((100% / 5) - 10px);
          }
          @include g.media(tablet) {
            width: calc((100% / 4) - 10px);
          }
          @include g.media(sp) {
            width: calc((100% / 3) - 10px);
          }
          @include g.media(sp-m) {
            width: calc((100% / 2) - 10px);
          }
        }
      }
    }
  }
}
