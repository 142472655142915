@use "../../global" as g;
// ----------------------------------------------------
//  見出し
// ----------------------------------------------------
.title {
  &.-style01 {
    margin-bottom: 50px;
    font-size: 4.2rem;
    line-height: 1.4;
    text-align: center;
    // font-weight: bold;
    @include g.media(sp) {
      margin-bottom: 30px;
      font-size: 3rem;
    }
    @include g.media(sp-m) {
      font-size: 2.1rem;
    }
  }
  &.-style02 {
    margin-bottom: 45px;
    font-size: 3.4rem;
    line-height: 1.4;
    text-align: center;
    font-weight: bold;
    &.-left {
      text-align: left;
    }
    @include g.media(sp) {
      margin-bottom: 30px;
      font-size: 3rem;
    }
    @include g.media(sp-m) {
      font-size: 2.1rem;
    }
  }
  &.-style03 {
    margin-top: 45px;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 3.2rem;
    text-align: center;
    &.-left {
      text-align: left;
    }
    @include g.media(sp) {
      margin-top: 25px;
      font-size: 2rem;
    }
  }
  &.-style04 {
    margin-top: 45px;
    margin-bottom: 40px;
    padding: 17px 40px;
    background: g.$color-light;
    line-height: 1.5;
    font-size: 1.8rem;
    font-weight: bold;
    @include g.media(sp) {
      margin: 30px -15px 20px;
      padding: 12px 15px;
      font-size: 1.4rem;
    }
  }
  &.-style05 {
    margin-top: 45px;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 2.8rem;
    @include g.media(sp) {
      margin-top: 25px;
      font-size: 1.7rem;
    }
  }
}
