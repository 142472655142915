@use "../global" as g;
// ----------------------------------------------------
//  フッター
// ----------------------------------------------------
.footer {
  background-color:#fff;
  position: relative;
  margin: 0 auto 0;
  // page top
  &__pageTop {
    // position: absolute;
    // top: -90px;
    right: 30px;
    margin: 0;
    @include g.media(sp) {
      // top: -60px;
      right: 15px;
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
  // サイトマップと公式アカウント
  &__main {
    position: relative;
    padding: 0 30px;
    background: url(../img/share/bg_footer_casibo_pc.jpg) center bottom no-repeat;
    background-size: cover;
    @include g.media(tablet-s) {
      background: #fdfaf4;
      background-size: 100% auto;
    }
    @include g.media(sp) {
      padding: 0;
      background: none;
    }
    &Inner {
      position:relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: g.$width-base;
      margin: auto;
      padding: 94px 0 84px;
      &::after {
        position:absolute;
        content:'';
        bottom: 4px;
        right: 330px;
        background: url(../img/share/bg_footer_casibo.png) no-repeat;
        background-size: 100%;
        width: 160px;
        height: 206px;
      }
      @include g.media(tablet-l) {
        padding: 30px 0 84px;
        &::after {
          bottom: 8px;
          right: 150px;
          width: 106px;
          height: 136px;
        }
      }
      @include g.media(sp) {
        display: block;
        padding: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  &__sitemap {
    position: relative;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    z-index: 1;
    @include g.media(sp) {
      display: block;
      margin-bottom: 35px;
      padding: 0 0 115px;
      background-color: #fdfaf4;
      border-top: 1px solid #D8D5D5;
      &::before,
      &::after {
        position:absolute;
        content:'';
        bottom: 0;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: -1;
      }
      &::before {
        left: 0;
        background-image: url(../img/share/bg_footer_casibo_sp_l.png);
        width: 134px;
        height: 240px;
      }
      &::after {
        right: 0;
        background-image: url(../img/share/bg_footer_casibo_sp_r.png);
        width: 115px;
        height: 240px;
      }
    }
    li {
      margin: 0 70px 20px 0;
      font-size: 1.4rem;
      font-weight: bold;
      @include g.media(tablet-l) {
        margin-right: 50px;
      }
      @include g.media(tablet) {
        margin-bottom: 15px;
      }
      @include g.media(tablet-s) {
        width: 50%;
        margin-right: 0;
      }
      @include g.media(sp) {
        width: auto;
        margin: 0;
        border-bottom: 1px solid #D8D5D5;
      }
      // ホーム
      &:first-child {
        width: 100%;
        a {
          padding-right: 40px;
          background: url(../img/share/icon_home.svg) no-repeat right center;
          font-size: 2rem;
          transition: none;
          @include g.media(sp) {
            padding: 11px 15px 8px 48px;
            background-position: 15px center;
            background-size: 26px auto;
            font-size: 1.8rem;
          }
        }
      }
    }
    // オンラインショップとファンサイトのボタン
    & &Btn {
      margin-right: 30px;
      @include g.media(tablet-s) {
        margin-right: 0;
      }
      @include g.media(sp) {
        margin: 22px auto 15px;
        padding: 0 15px;
        text-align: center;
        border-bottom: 0;
        & + .footer__sitemapBtn {
          margin-top: 15px;
        }
      }
      a {
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        min-width: 220px;
        padding: 5px 15px;
        background: g.$color-main;
        border: 2px solid g.$color-main;
        border-radius: 100px;
        color: white;
        font-weight: bold;
        text-align: center;
        line-height: 1.4;
        transition: all .5s;
        @include g.media(pc) {
          &:hover {
            text-decoration: none;
            background: white;
            color: g.$color-main;
          }
        }
        &[target="_blank"] {
          &::after {
            content: none;
          }
          .blankIcon {
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            @include g.media(sp) {
              right: 12px;
            }
          }
        }
        @include g.media(tablet-s) {
          min-width: 190px;
          text-align: left;
        }
        @include g.media(sp) {
          width: 100%;
          min-width: 160px;
          max-width: 205px;
          padding: 8px 0;
          font-size: 1.4rem;
          text-align: center;
        }
      }
    }
    a {
      color: g.$color-font;
      .blankIcon {
        transform: translateY(6px);
      }
      @include g.media(sp) {
        position: relative;
        display: block;
        padding: 8px 15px;
        font-size: 1.8rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 15px;
          width: 24px;
          height: 100%;
          background: url(../img/share/arrow_circle_right_red.svg) no-repeat center;
          background-size: 100% auto;
        }
        &[target="_blank"] {
          &::before {
            content: none;
          }
          .blankIcon {
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
  &__socialmedia {
    width: 300px;
    text-align: center;
    margin-left: 150px;
    @include g.media(tablet-l) {
      margin-left: 100px;
    }
    @include g.media(tablet) {
      margin-left: 50px;
    }
    @include g.media(tablet-s) {
      margin-left: 15px;
    }
    @include g.media(sp) {
      width: auto;
      margin: auto;
    }
    @include g.media(sp) {
      position: relative;
      &::before {
        position:absolute;
        content:'';
        background: url(../img/share/bg_footer_casibo.png) no-repeat;
        background-size: 100%;
        top: -155px;
        left: -15px;
        right: 0;
        margin: auto;
        width: 90px;
        height: 116px;
        z-index: 1;
      }
    }
    dl {
      dt {
        margin-bottom: 20px;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        @include g.media(sp) {
          font-size: 1.9rem;
        }
      }
    }
    a {
      &:hover {
        .btn {
          text-decoration: underline;
        }
      }
    }
    .imgBox {
      background-color: #fff;
      border: 2px solid #E2E2E2;
      border-radius: 10px;
      padding: 17px 37px;
      @include g.media(sp) {
        box-sizing: border-box;
        width: 28.5rem;
        margin: auto;
      }
      img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        @include g.media(sp) {
        }
      }
    }
    .btn {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      @include g.media(sp) {
        font-size: 1.9rem;
      }
    }
  }
  &__sns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include g.media(sp) {
      justify-content: center;
      padding: 0 30px;
      li {
        margin: 0 15px;
      }
    }
    @include g.media(sp-s) {
      padding: 0 15px;
      li {
        margin: 0 5px;
      }
    }
  }
  &__account {
    @include g.media(sp-s) {
      padding: 0 15px;
    }
  }
  // フッターのリンクとCopyright
  &__sub {
    display: flex;
    flex-wrap: wrap;
    max-width: g.$width-base;
    margin: auto;
    padding: 16px 30px;
    background: white;
    @include g.media(tablet-l) {
      display: block;
      padding: 16px 30px;
    }
    @include g.media(sp) {
      display: block;
      padding: 16px 15px;
    }
  }
  &__nav {
    display: flex;
    flex: 1;
    margin: 0 auto 0 0;
    @include g.media(sp) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto 30px;
    }
    li {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      @include g.media(tablet-l) {
        margin-bottom: 15px;
        margin-right: 2.5vw;
      }
      @include g.media(sp) {
        position: relative;
        margin: 0 15px;
        &::after {
          content: "";
          position: absolute;
          top: 25%;
          right: -15px;
          width: 1px;
          height: 50%;
          background: #D8D5D5;
        }
        &:last-child,
        &:nth-child(3) {
          &::after {
            content: none;
          }
        }
      }
      @include g.media(sp-s) {
        margin: 0 10px;
        &::after {
          right: -10px;
        }
      }
      @include g.media(w320) {
        &:nth-child(2) {
          &::after {
            content: none;
          }
        }
        &:nth-child(3) {
          &::after {
            content: "";
          }
        }
      }
    }
    a {
      color: #707070;
      font-size: 1.4rem;
      @include g.media(sp) {
        font-size: 2.8vw;
      }
      @include g.media(w320) {
        font-size: 2.0vw;
      }
    }
  }
  &__logo {
    width: 271px;
    margin: 0;
    @include g.media(tablet-l) {
      margin: 0 0 0 auto;
    }
    @include g.media(sp) {
      width: 237px;
      margin: 0 auto;
      text-align: center;
    }
  }
  &__copyright {
    width: 100%;
    margin: 0;
    text-align: right;
    color: #707070;
    @include g.media(sp) {
      text-align: center;
    }
    @include g.media(sp) {
      text-align: center;
    }
    small {
      font-size: 1.4rem;
      @include g.media(sp) {
        font-size: .8rem;
      }
    }
  }
}

