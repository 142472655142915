@use "../../global" as g;
// ----------------------------------------------------
//  パンくずリスト
// ----------------------------------------------------
.breadcrumb {
  margin: 17px 0 55px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @include g.media(sp) {
    display: block;
    margin: 15px 0 25px;
    line-height: 1.3;
  }
  &__item {
    list-style: none;
    margin: 0 13px 0 0;
    padding: 0;
    @include g.media(sp) {
      margin-right: 10px;
      display: inline;
    }
    &::after {
      content: "";
      padding-right: 20px;
      background: url(../img/share/arrow_breadcrumb.svg) no-repeat right center;
      @include g.media(sp) {
        padding-right: 11px;
        background-size: 5px auto;
        background-position: right .45em;
      }
    }
    &:last-child {
      margin-right: 0;
      &::after {
        content: none;
      }
    }
  }
  & &__link {
    color: g.$color-font;
    @include g.media(sp) {
      font-size: 1rem;
    }
  }
}

.breadcrumbArea {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .breadcrumbArea {
        padding: 0 15px;
  }
}