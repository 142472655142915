@use "../global" as g;
// ----------------------------------------------------
//  グローバルナビゲーションの右上のナビ
// ----------------------------------------------------
.utilityNav {
  margin: 0 55px 20px auto;
  @include g.media(tablet) {
    position: absolute;
    top: 20px;
    right: 30px;
  }
  @include g.media(sp) {
    position: static;
    margin: 0;
  }
  &__list {
    display: flex;
    align-items: center;
    @include g.media(sp) {
      display: block;
    }
  }
  &__item {
    margin-left: 30px;
    @include g.media(sp) {
      margin-left: 0;
      border-bottom: 2px solid #D8D5D5;
    }
    &.-global {
      a {
        font-size: 1.4rem;
        color: g.$color-font;
        @include g.media(sp) {
          font-weight: bold;
          font-size: 1.8rem;
        }
      }
    }
    &.-faq {
      a {
        // display: inline-block;
        padding: 3px 28px;
        background: g.$color-main;
        border: 2px solid g.$color-main;
        border-radius: 100px;
        font-weight: bold;
        color: white;
        .blankIcon {
          display: none;
        }
        @include g.media(sp) {
          padding: 13px 15px;
          background: none;
          border-radius: 0;
          border: 0;
          color: g.$color-font;
          .blankIcon {
            display: block;
          }
        }
        &:hover {
          @include g.media(pc) {
            background: white;
            color: g.$color-main;
            text-decoration: none;
          }
        }
      }
    }
  }
  &__link {
    .blankIcon {
      transform: translateY(0);
    }
    @include g.media(sp) {
      position: relative;
      display: block;
      padding: 13px 15px;
      font-size: 1.8rem;
      .blankIcon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }
}
