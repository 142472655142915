@use "../../global" as g;
// ----------------------------------------------------
//  テキストリンク
// ----------------------------------------------------
.textLink {
  $this: &;
  line-height: 1.4;
  &__item {
    margin-bottom: 8px;
    @include g.media(sp) {
      margin-bottom: 4px;
    }
  }
  & &__link {
    padding: 5px 0;
    color: g.$color-font;
    text-decoration: underline;
    @include g.media(sp) {
      display: inline-block;
      position: relative;
      font-size: 1.3rem;
      padding-left: 28px;
    }
    &::before {
      content: "";
      padding: 5px 0 5px 35px;
      background: url(../img/share/arrow_circle_right_red.svg) no-repeat center left;
      @include g.media(sp) {
        position: absolute;
        top: 5px;
        left: 0;
        width: 20px;
        height: 20px;
        padding: 0;
        background-size: 100% auto;
      }
    }
    &:hover {
      color: g.$color-main;
      text-decoration: none;
    }
    .blankIcon {
      vertical-align: middle;
      transform: translateY(-2px);
    }
  }
}
