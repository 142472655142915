@use "../../global" as g;
// -----------------------------------------
// トップページのお知らせ・ニュースリリース・更新情報
// -----------------------------------------
.top-info {
  margin: 0 calc(-50vw + 50%);
  background: g.$color-light;
  @include g.media(tablet-l) {
    margin: 0 -30px;
    padding: 0 30px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: g.$width-base;
    margin: auto;
    padding: 90px 0 1px;
  }
  &__item {
    width: calc(50% - 40px);
    margin-bottom: 100px;
    &.-news {
      width: 100%;
    }
    @include g.media(tablet-l) {
      width: calc(50% - 25px);
    }
    @include g.media(tablet-s) {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 25px;
    font-size: 3.2rem;
    font-weight: bold;
    @include g.media(sp) {
      font-size: 2.7rem;
    }
    @include g.media(sp-m) {
      font-size: 2.1rem;
    }
  }
}

