@use "../../global" as g;
// ----------------------------------------------------
//  商品詳細の商品詳細
// ----------------------------------------------------
.products-detailInfo {
  margin-bottom: 80px;
  @include g.media(sp) {
    margin-left: -15px;
    margin-right: -15px;
  }
  &__item {
    display: flex;
    padding: 40px 60px calc(40px - 1.5em) 40px;
    font-size: 1.8rem;
    @include g.media(tablet) {
      padding-right: 40px;
    }
    @include g.media(sp) {
      padding: 20px 15px calc(20px - 1.5em);
      font-size: 1.3rem;
    }
    &:nth-child(odd) {
      background-color: g.$color-light;
    }
  }
  &__title {
    width: 220px;
    margin-right: 10px;
    font-weight: bold;
    @include g.media(tablet) {
      width: 150px;
    }
    @include g.media(sp) {
      width: 90px;
      margin-right: 10px;
    }
  }
  &__body {
    flex: 1;
    ul {
      margin-bottom: 1.5em;
    }

    .guide {
      text-indent: -1em;
      padding-left: 1em;
      font-size: 1.4rem;
    }
  }
  &__subTitle {
    margin-top: 40px;
    margin-bottom: 25px;
    padding: 7px 10px;
    background: white;
    font-weight: bold;
    line-height: 1.3;
    @include g.media(sp) {
      margin-bottom: 15px;
    }
    span {
      display: inline-block;
      font-weight: normal;
      font-size: 1.4rem;
      @include g.media(sp) {
        font-size: .9rem;
      }
    }
  }
  // アレルギー情報
  &__allergy {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    margin-left: 1px;
    &Area {
      @include g.media(sp) {
        margin-left: -100px;
      }
    }
    &Item {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: calc((100% / 9) + 1px);
      margin-bottom: 10px;
      margin-left: -1px;
      background: white;
      border: 1px solid g.$color-gray;
      text-align: center;
      &.-cheked {
        background: #FFF5F7;
        .products-detailInfo__allergyTitle {
          background: white;
        }
      }
      @include g.media(tablet-l) {
        width: calc((100% / 8) + 1px);
      }
      @include g.media(tablet) {
        width: calc((100% / 7) + 1px);
      }
      @include g.media(tablet-s) {
        width: calc((100% / 6) + 1px);
      }
      @include g.media(sp) {
        width: calc((100% / 8) + 1px);
      }
      @include g.media(sp-m) {
        width: calc((100% / 7) + 1px);
      }
      @include g.media(sp-s) {
        width: calc((100% / 5) + 1px);
      }
      @include g.media(w320) {
        width: calc((100% / 4) + 1px);
      }
    }
    &Icon {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 15px;
      padding: 0 15px;
      width: 100%;
      height: 60px;
      img {
        max-height: 100%;
      }
      @include g.media(tablet) {
        height: 50px;
      }
      @include g.media(tablet-s) {
        height: 45px;
      }
      @include g.media(sp) {
        height: 35px;
      }
    }
    &Title {
      display: flex;
      min-height: 2.9em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 0 7px;
      font-size: 1.4rem;
      line-height: 1.4;
      @include g.media(sp) {
        padding: 10px 0 2px;
        font-size: 1.3rem;
      }
      &Text {
        padding: 0 5px;
      }
    }
    &Body {
      margin-top: auto;
      padding: 7px 0;
      border-top: 1px solid g.$color-gray;
    }
  }
  &__note {
    margin-top: 15px;
    font-size: 1.4rem;
    text-align: right;
    @include g.media(sp) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  &__sns {
    display: flex;
    justify-content: flex-end;
    @include g.media(sp) {
      padding-right: 15px;
      padding-left: 15px;
    }
    &Item {
      margin-left: 13px;
    }
  }
}
