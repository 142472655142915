@use "../../global" as g;
// ----------------------------------------------------
//  商品詳細の発売日
// ----------------------------------------------------
.products-saleDate {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @include g.media(tablet-s) {
    display: block;
  }
  &__table {
    width: 48%;
    @include g.media(tablet) {
      width: 49%;
      font-size: 1.6rem;
    }
    @include g.media(tablet-s) {
      width: 100%;
      &:last-child {
        margin-top: -1px;
        tr {
          &:nth-child(odd) {
            background: g.$color-light;
          }
          &:nth-child(even) {
            background: white;
          }
        }
      }
    }
    @include g.media(sp) {
      font-size: 1.3rem;
    }
    caption {
      height: 0;
      overflow: hidden;
    }
    tr {
      &:nth-child(even) {
        background: g.$color-light;
      }
    }
    th {
      width: 40%;
      padding: 5px 10px;
      border: 1px solid g.$color-gray;
      font-weight: normal;
      text-align: left;
      // vertical-align: top;
      line-height: 1.6;
      @include g.media(tablet-s) {
        width: 35%;
      }
    }
    td {
      // vertical-align: top;
      padding: 5px 10px;
      border: 1px solid g.$color-gray;
      line-height: 1.6;
    }
  }
  // 販売地域について
  &__toggle {
    margin-bottom: 40px;
    &Body {
      height: 0;
      padding: 0 20px;
      overflow-y: hidden;
      transition: height 0.3s;
      @include g.media(sp) {
        margin-left: -100px;
        padding: 0;
      }
    }
    &Btn {
      button {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 18px 20px;
        background: #E2E2E2;
        text-align: left;
        font-weight: bold;
        color: g.$color-font;
        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 6px);
          right: 26px;
          width: 3px;
          height: 15px;
          background: g.$color-main;
          border-radius: 5px;
          transition: .3s;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 20px;
          width: 15px;
          height: 3px;
          background: g.$color-main;
          border-radius: 5px;
          transition: .3s;
        }
        &[aria-expanded='true'] {
          &::before {
            transform: rotate(90deg);
            opacity: 0;
          }
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &__areaTable {
    caption {
      height: 0;
      overflow: hidden;
    }
    tr {
      &:nth-child(even) {
        background: g.$color-light;
      }
    }
    th {
      padding: 7px 15px;
      border: 1px solid g.$color-gray;
      font-weight: bold;
      text-align: left;
      line-height: 1.6;
      @include g.media(sp) {
        padding: 7px 10px;
      }
      &:first-child {
        width: 150px;
        @include g.media(sp) {
          width: 100px;
        }
      }
    }
    td {
      padding: 7px 15px;
      border: 1px solid g.$color-gray;
      line-height: 1.6;
      @include g.media(sp) {
        padding: 7px 10px;
      }
    }
  }
}
