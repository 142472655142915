@use "../../global" as g;
// ----------------------------------------------------
//  記事
// ----------------------------------------------------
.entry {
  padding-bottom: 120px;
  @include g.media(sp) {
    padding-bottom: 85px;
  }
  &__title {
    font-size: 110%;
    font-weight: bold;
  }
  &__body {
    word-break: break-all;
    h3 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 135%;
      font-weight: bold;
    }
    h4 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 125%;
      font-weight: bold;
    }
    h5 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 115%;
      font-weight: bold;
    }
    h6 {
      margin-top: 30px;
      margin-bottom: 5px;
      font-size: 105%;
      font-weight: bold;
    }
    ul, ol, dl, table {
      margin-bottom: 1.5em;
    }
    table {
      width: 100%;
      th {
        padding: 10px 15px;
        background: #f7f7f7;
        border: 1px solid #ccc;
      }
      td {
        padding: 10px 15px;
        border: 1px solid #ccc;
      }
    }
    .blankIcon {
      vertical-align: baseline;
      margin-left: 3px;
      margin-right: 5px;
    }
  }
}
