@use "../global" as g;
// ----------------------------------------------------
//  ヘッダーの検索
// ----------------------------------------------------
.navSearch {
  &__btn {
    position: absolute;
    top: 16px;
    right: calc(50% - 598px);
    @include g.media(tablet-l) {
      right: 30px;
    }
    @include g.media(sp) {
      top: 0;
      right: 60px;
      width: 50px;
      height: 50px;
      padding-top: 0;
      padding-bottom: 5px;
      img {
        width: 32px;
        transition: opacity .5s;
      }
      &.-open {
        background: rgba(g.$color-main, .8) url(../img/share/btn_search_white.svg) no-repeat center 44%;
        background-size: 32px auto;
        img {
          opacity: 0;
        }
      }
    }
  }
  &__body {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 108px;
    background: rgba(g.$color-main, .8);
    transform: translateY(-250px);
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    &.-open {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
    @include g.media(sp) {
      top: 50px;
      height: auto;
      padding: 20px 0;
    }
    &Inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      max-width: g.$width-base;
      margin: auto;
      padding: 0 30px;
      @include g.media(sp) {
        padding: 0 15px;
      }
    }
  }
  &__form {
    flex: 1;
    display: flex;
    width: 600px;
    border: 2px solid #fff;
    border-radius: 100px;
  }
  &__searchInput {
    box-sizing: border-box;
    width: calc(100% - 85px);
    margin: 0;
    padding: 13px 25px;
    border: 0;
    border-radius: 100px 0 0 100px;
    @include g.media(sp) {
      padding: 12px 5px 12px 20px;
      width: calc(100% - 55px);
    }
  }
  &__submit {
    width: 85px;
    margin: 0;
    padding: 0;
    border: 0;
    background: g.$color-main url(../img/share/btn_search_white.svg) no-repeat center;
    border-radius: 0 100px 100px 0;
    color: white;
    text-indent: -999em;
    @include g.media(sp) {
      width: 55px;
      background-position: 42% center;
    }
  }
  &__close {
    margin-left: 30px;
    @include g.media(sp) {
      margin-left: 15px;
    }
  }
}

