@use "../../global" as g;
// -----------------------------------------
// ボタンのリスト
// -----------------------------------------
.btnList {
  &__item {
    margin-bottom: 40px;
    @include g.media(sp) {
      margin-bottom: 30px;
    }
  }
}

