@use "../../global" as g;
// ----------------------------------------------------
//  ページトップ部分
// ----------------------------------------------------
.js-pageTop {
  opacity: 0;
  transition: all .5s;
  &.-show {
    opacity: 1;
  }
  &.-fixed {
    position: fixed;
    top: auto;
    bottom: 30px;
    @include g.media(sp) {
      bottom: 15px;
    }
  }
  &.-absolute {
    position: absolute;
    top: -90px;
    @include g.media(sp) {
      top: -60px;
    }
  }
}
