@use "../global" as g;
// ----------------------------------------------------
//  メイン
// ----------------------------------------------------
.main {
  margin: 0 auto;
  max-width: g.$width-base;
  padding: 0 30px;
  @include g.media(sp) {
    padding: 0 15px;
  }
}

