@use "../../global" as g;
// ----------------------------------------------------
//  サイトマップ
// ----------------------------------------------------

// 3階層目のリストベース
%sitemap__3rd {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 40px;
  margin-left: 20px;
  margin-bottom: 15px;
  @include g.media(sp) {
    gap: 5px 20px;
    margin-left: 5px;
    padding: 0;
  }
  li {
    padding-top: 0;
    border: none;
    font-weight: 500;
    font-size: 1.8rem;
    @include g.media(sp) {
      font-size: 1.3rem;
    }
    a {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 18px;
      background: url(../img/share/arrow_right_red.svg) no-repeat left center;
      background-size: 8px auto;
    }
  }
}

.sitemap {
  padding-bottom: 100px;
  @include g.media(sp) {
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 40px;
  }
  &__list {
    > li {
      margin-bottom: 80px;
      padding: 0;
      font-size: 3.4rem;
      font-weight: bold;
      @include g.media(sp) {
        margin-bottom: 50px;
        font-size: 2rem;
      }
      a,
      span {
        display: block;
        padding: 7px 20px 7px 55px;
        color: g.$color-font;
        background: #f7f7f7 url(../img/share/arrow_circle_right_red.svg) no-repeat 20px center;
        background-size: 22px auto;
        + .sitemap__3rd {
          margin-top: 20px;
          @include g.media(sp) {
            margin-left: 25px;
          }
        }
        .blankIcon {
          vertical-align: baseline;
        }
      }
      span {
        padding-left: 20px;
        background-image: none;
      }
      a {
        &:hover {
          text-decoration: none;
          color: g.$color-link;
        }
      }
      ul {
        padding: 0 20px;
        background: white;
        li {
          margin-top: 5px;
          padding-top: 5px;
          background: none;
          border-top: 1px solid #D8D5D5;
          font-size: 2rem;
          @include g.media(sp) {
            font-size: 1.4rem;
          }
          &:first-child {
            border-top: 0;
          }
          a {
            display: inline-block;
            padding-left: 35px;
            background-color: #fff;
            background-position: left center;
          }
          ul {
            @extend %sitemap__3rd;
          }
        }
      }
      .sitemap__3rd {
        @extend %sitemap__3rd;
      }
    }
  }
}
