@use "../../global" as g;
// -----------------------------------------
// ボタン
// -----------------------------------------
.btn {
  $this: &;
  &__subText {
    display: block;
    font-size: 1.8rem;
    @include g.media(sp) {
      font-size: 1.3rem;
    }
  }
  & &__link {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    min-width: 300px;
    padding: 12px 0;
    background: g.$color-main;
    border: 3px solid g.$color-main;
    border-radius: 100px;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 1.4;
    transition: all .5s;
    @include g.media(sp) {
      min-width: 270px;
      padding: 12px 40px;
    }
    @include g.media(w320) {
      min-width: 0;
      padding: 12px 28px;
      font-size: 1.4rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 15px;
      width: 12px;
      height: 100%;
      background: url(../img/share/arrow_right_white.svg) no-repeat center;
      background-size: 100% auto;
      @include g.media(sp) {
        right: 13px;
      }
    }
    &[target="_blank"] {
      &::after {
        content: none;
      }
      .blankIcon {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        @include g.media(sp) {
          right: 12px;
        }
      }
    }
    &:hover {
      text-decoration: none;
      background: white;
      color: g.$color-main;
      &::after {
        background-image: url(../img/share/arrow_right_red.svg);
      }
    }
  }
  // 青いボタン
  &.-blue {
    #{$this} {
      &__link {
        background-color: g.$color-blue;
        border-color: g.$color-blue;
        &:hover {
          text-decoration: none;
          background: white;
          color: g.$color-blue;
          &::after {
            background-image: url(../img/share/arrow_right_blue.svg);
          }
        }
      }
    }
  }
  // グレーのボタン
  &.-gray {
    #{$this} {
      &__link {
        background-color: g.$color-gray;
        border-color: g.$color-gray;
        color: g.$color-font;
        &::after {
          width: 14px;
          background-image: url(../img/share/arrow_right_gray.svg);
          @include g.media(sp) {
            width: 10px;
          }
        }
        &:hover {
          text-decoration: none;
          background: white;
          color: g.$color-font;
        }
      }
    }
  }
  // お問い合わせ窓口ボタン
  &.-contact {
    #{$this} {
      &__link {
        background-color: white;
        border-color: g.$color-red;
        color: g.$color-red;
        &::before {
          content: "";
          padding: 13px 0 13px 73px;
          background: url(../img/share/icon_contact.png) left center no-repeat;
          background-size: 72px auto;
          @include g.media(sp) {
            padding-left: 50px;
            background-size: 48px auto;
          }
        }
        &:hover {
          text-decoration: none;
          background: g.$color-red;
          color: white;
          &::before {
            background-image: url(../img/share/icon_contact_white.png);
          }
        }
      }
    }
  }
  // 検索する
  &.-search {
    #{$this} {
      &__link {
        @include g.media(sp) {
          width: 100%;
        }
        &::after {
          right: 25px;
          width: 34px;
          background-image: url(../img/share/btn_search_white.svg);
          background-position: center 42%;
          @include g.media(sp) {
            right: 20px;
            width: 28px;
          }
        }
        &:hover {
          text-decoration: none;
          &::after {
            background-image: url(../img/share/btn_search_blue.svg);
          }
        }
      }
    }
  }
  // もっと見る
  &.-more {
    #{$this} {
      &__link {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
  // 大きいボタン
  &.-large {
    #{$this} {
      &__link {
        min-width: 520px;
        font-size: 2.8rem;
        padding-top: 17px;
        padding-bottom: 17px;
        @include g.media(sp) {
          display: block;
          min-width: 0;
          font-size: 1.7rem;
        }
      }
    }
  }
  // 小さいボタン
  &.-small {
    #{$this} {
      &__link {
        padding: 7px 20px 5px;
        min-width: 208px;
        font-size: 1.6rem;
        @include g.media(sp) {
          display: block;
          min-width: 0;
          width: auto;
          padding-right: 32px;
          padding-left: 7px;
        }
        &::after {
          right: 10px;
          @include g.media(sp) {
            right: 8px;
          }
        }
      }
    }
  }
  // アレルギー情報の同意ボタン
  &.-allergy {
    margin-top: 50px;
    margin-bottom: 80px;
    text-align: center;
    @include g.media(sp) {
      margin: 30px auto 45px;
    }
    #{$this} {
      &__link {
        background-color: g.$color-blue;
        border-color: g.$color-blue;
        font-size: 2.6rem;
        @include g.media(pc) {
          min-width: 520px;
        }
        @include g.media(sp) {
          display: block;
          font-size: 1.6rem;
        }
        &:hover {
          text-decoration: none;
          background: white;
          color: g.$color-blue;
          &::after {
            background-image: url(../img/share/arrow_right_blue.svg);
          }
        }
      }
    }
  }
  // 戻る
  &.-back {
    #{$this} {
      &__link {
        padding-top: 7px;
        padding-bottom: 7px;
        &::after {
          right: auto;
          left: 25px;
          width: 18px;
          transform: rotate(-180deg);
          @include g.media(sp) {
            left: 20px;
            width: 14px;
          }
        }
      }
    }
  }
  // 矢印アイコン
  &.-arw {
    position: relative;
    display: inline-block;
    color: #000;
    line-height: 1.4;
    padding: 5px 34px 5px 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 100%;
      background: url(../img/share/arrow_circle_right_red.svg) no-repeat center;
      background-size: 100% auto;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    a {
      color: #000;
    }
  }
}

.linkBtn01 a {
  background-color: #fff;
  border: 2px solid #cabdb0;
  border-radius: 8px;
  display: block;
  font-size: 16px;
  line-height: 1.3;
  padding: 10px 20px 8px 28px;
  position: relative;
  color: #222;
}
.linkBtn01 a:after {
  background: url(../img/icon_arrow_red.png) no-repeat 0;
  content: "";
  display: block;
  height: 12px;
  left: 12px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 7px;
}