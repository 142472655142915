@use "../../global" as g;
// display
.none {
  display: none;
}

.pc {
  @include g.media(sp) {
    display: none;
  }
}
.sp {
  display: none;
  @include g.media(sp) {
    display: block;
  }
}
.sp-s {
  display: none;
  @include g.media(sp-s) {
    display: block;
  }
}
