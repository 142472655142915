@use "../../global" as g;
// ----------------------------------------------------
//  お問い合わせ先の電話番号
// ----------------------------------------------------
.contactTEL {
  line-height: 1.2;
  font-size: 3.6rem;
  font-weight: bold;
  color: g.$color-main;
  @include g.media(sp) {
    font-size: 2.6rem;
  }
  &::before {
    content: "";
    padding-left: 48px;
    background: url(../img/share/icon_tel.png) no-repeat left 15%;
    background-size: 38px;
    @include g.media(sp) {
      padding: 5px 0 5px 40px;
      background-size: 34px;
    }
  }
}
