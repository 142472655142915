@use "../../global" as g;
// ----------------------------------------------------
//  商品詳細の写真と商品説明
// ----------------------------------------------------
.products-detailMain {
  display: flex;
  margin-bottom: 80px;
  @include g.media(sp) {
    display: block;
  }
  &__image {
    width: 500px;
    margin-right: 60px;
    img {
      border: 1px solid g.$color-gray;
    }
    @include g.media(tablet) {
      margin-right: 30px;
    }
    @include g.media(sp) {
      margin: 0 auto 28px;
      width: auto;
    }
  }
  &__text {
    flex: 1;
    min-width: 450px;
    @include g.media(tablet-s) {
      min-width: 400px;
    }
    @include g.media(sp) {
      min-width: 0;
    }
  }
  &__btnList {
    margin-top: 80px;
    @include g.media(sp) {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  &__btnItem {
    margin-top: 20px;
  }
  & &__btnLink {
    @include g.media(sp) {
      display: block;
      margin: auto;
      max-width: 320px;
    }
  }
}
